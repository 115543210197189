import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import OHLGang from "./OHLGang";
import { Close } from '@material-ui/icons';
import { generalforeman } from '../../../../config/apiList';
import Service from '../../../../config/networkutils';
import { withSnackbar } from "notistack";
import { getCookie } from '../../../../utils/CookieHelper';
import MaterialTable from 'material-table';
import { LoopCircleLoading } from 'react-loadingg';
import { getActiveRoles, getCRM, getEmployees, getGeneralforeman, getOwnerDelivery, getRegion, getSubSector } from '../../../../config/PromisesHandler';
import { getIdFnameLnameObj, getIdNameFomattedObj } from '../../../../utils/reuseFunctions';

const token = getCookie("ptd_ts_token");


const useStyle = (theme) => ({
    headRow: {
        backgroundColor: "#D5E1E5",
        // minWidth: '100%'
    },
    selectedRow: {
        backgroundColor: "#EDFAFF",
    },
})



class OHLStaffTables extends React.Component {
    state = {
        gfs: [],
        showGFAdd: false,
        isLoading: true,
        allOHLEmp: [],


    }

    // ohlroles = [
    //     { 58: "General Foreman" },
    //     { 171: "Apprentice General Foreman" },
    //     { 124: "Senior General Foreman" },
    //     { 45: "Drivers" },
    //     { 138: "Storeman" },
    //     { 196: "General Operative" },
    //     { 203: "Foreman 1" },
    //     { 204: "Foreman 2" },
    //     { 205: "LE1" },
    //     { 206: "LE2" },
    //     { 207: "LH" },
    //     { 208: "Apprentice Linesman" },
    //     { 209: "Trainee Linesman" },
    //     { 210: "SPO" },
    // ]

    gfRoleId = null
    ohlroles = null

    componentDidMount() {
        this.gfRoleId = this.props.gfRoleId
        this.ohlroles = this.props.roles

        Promise.all([getEmployees(), getGeneralforeman(this.props.discipline), getActiveRoles(), getSubSector(), getRegion(), getOwnerDelivery(), getCRM()])
            .then(res => {
                let ohlemps = []
                // Filtering all the OHL Role employees mentioned in the this.ohlroles
                for (let empin = 0; empin < res[0].length; empin++) {
                    this.ohlroles.forEach(roleId => {
                        if (res[0][empin].job_title === roleId) {
                            ohlemps.push(res[0][empin])
                        }
                    })
                }

                // filtering only generalforemen role
                let allGfs = ohlemps.filter(emp => emp.job_title === this.gfRoleId)
                let allGangEmps = ohlemps.filter(emp => emp.job_title !== this.gfRoleId)


                // filtering not Allocated generalforemen
                let unAllocatedGF = [...allGfs];
                for (let gfEmpIndex = 0; gfEmpIndex < res[1].length; gfEmpIndex++) {
                    let filteredGfNotAllocatedEmps = unAllocatedGF.filter(emp => emp.id !== res[1][gfEmpIndex].ptd_employee)
                    unAllocatedGF = [...filteredGfNotAllocatedEmps]
                }
                // if (unAllocatedGF.length === 0) {

                //     unAllocatedGF = [...allGfs];
                // }

                let rolesObj = {}
                res[2].forEach(ele => {

                    let newData = { [ele.id]: ele.job_roles }
                    rolesObj = { ...rolesObj, ...newData }
                })
                // getting lookup objects
                let ohlEempObj = getIdFnameLnameObj(ohlemps)
                let allSectorLupObj = getIdNameFomattedObj(res[3])
                let reg = getIdNameFomattedObj(res[4])
                let odu = getIdNameFomattedObj(res[5])
                this.setState({ allGfs, ohlEempObj, unAllocatedGF, allGangEmps, gfs: res[1], rolesObj, allSectors: res[3], allSectorLupObj, owner_region: reg, owner_delivery_unit: odu, allActiveRoles: res[2], allCRMS: res[6] }, () => this.setState({ isLoading: false }))
            })

            .catch(e => {
                this.setState({ isLoading: false })
            })

    }
    postOps(data) {
        Service.post(generalforeman, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        }).then(res => {
            let updatedGfs = [...this.state.gfs]
            let Notallocated = [...this.state.unAllocatedGF]
            updatedGfs.push(res.data)
            let removedTheAdded = Notallocated.filter(emp => emp.id !== res.data.ptd_employee)
            this.setState({ gfs: updatedGfs, unAllocatedGF: removedTheAdded, showGFAdd: false }, () => this.props.enqueueSnackbar('Client added successfully', {
                variant: 'success'
            }))
        })

            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }
    deleteOps(data) {
        Service.delete(generalforeman + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            }
        }).then(res => {
            let gffs = [...this.state.gfs]
            gffs = gffs.filter(gf => gf.id !== data.id)
            let dupGF = [...this.state.allGfs]
            dupGF = dupGF.filter(gf => gf.id === data.ptd_employee)
            let dupUnAllocatedGF = [dupGF[0], ...this.state.unAllocatedGF]
            this.props.enqueueSnackbar('Delete operation Success !', { variant: 'success' })
            this.setState({ gfs: gffs, unAllocatedGF: dupUnAllocatedGF })
        }).catch(error => {
            this.props.enqueueSnackbar('Delete operation failed !', { variant: 'error' })
        })
    }
    render() {
        return (
            <>
                {
                    this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                        <Box>
                            <Box marginTop={2} marginBottom={2} style={{fontWeight:'bold', fontSize:"15px"}}>
                                {this.props.name}
                            </Box>
                            <Paper elevation={3} variant={"outlined"}>

                                {/* <MaterialTable
                                title="General foremen and their Gangs"
                                columns={[{ title: 'General Foremen', field: 'ptd_employee', lookup: this.state.ohlEempObj }]}
                                data={this.state.gfs}
                                options={{
                                    search: true,
                                    paging: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    // fixedColumns: {
                                    //     left: 1,
                                    //     right: 0
                                    // },
                                    // exportButton: { csv: true, pdf: false },
                                    // filtering: true
                                }}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'Add General Foreman',
                                        isFreeAction: true,
                                        onClick: () => { this.setState({ showGFAdd: true }) }
                                    }
                                ]}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.deleteOps(oldData)
                                                resolve()
                                            }, 1000)
                                        }),


                                }}
                                detailPanel={rowData => ( */}
                                {/* // <DemandVsCapacityGraph rowData={rowData} columns={this.state.demandVsCapacityColArr} role={this.state.roles} /> */}
                                <OHLGang
                                    ohlEmpObj={this.state.ohlEempObj}
                                    allOHLEmp={this.state.allGangEmps}
                                    allSectors={this.state.allSectors}
                                    allSectorLupObj={this.state.allSectorLupObj}
                                    owner_delivery_unit={this.state.owner_delivery_unit}
                                    owner_region={this.state.owner_region}
                                    gf={this.props.rowData}
                                    rolesObj={this.state.rolesObj}
                                    allActiveRoles={this.state.allActiveRoles}
                                    getActiveRoles={getActiveRoles}
                                    allCRMS={this.state.allCRMS}
                                    option={this.props.option}
                                />
                                {/* //     )}
                            // >

                            // </MaterialTable> */}
                                {/* <List dense={true}>
                                    {this.state.gfs.map((value, index) => {
                                        return (
                                            <div>
                                                <ListItem button>
                                                    <ListItemText style={{ padding: "10px" }}
                                                        primary={this.state.ohlEempObj[value.ptd_employee]}
                                                        secondary={null}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end">
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>

                                                </ListItem>
                                                <div>
                                                    {index !== this.state.gfs.length - 1 ?
                                                        <Divider variant="middle" style={{ backgroundColor: getSecondaryColor() }} /> : null}
                                                </div>
                                            </div>)
                                    })}
                                </List> */}

                                <Dialog open={this.state.showGFAdd} onClose={() => this.setState({ showGFAdd: false })} maxWidth={"lg"}>
                                    <DialogTitle>
                                        <Typography>Add General Foreman</Typography>
                                    </DialogTitle>
                                    <DialogContent style={{ padding: 0 }}>
                                        <MaterialTable
                                            title="Employees"
                                            columns={[
                                                { title: 'Employee No', field: 'emp_no', },
                                                { title: 'First Name', field: 'first_name', },
                                                { title: 'Last Name', field: 'last_name', },
                                                { title: 'Email Address', field: 'email', },
                                                { title: 'Role', field: 'job_title', lookup: this.state.rolesObj },
                                                { title: 'Owner Region', field: 'owner_region', lookup: this.state.owner_region },
                                                { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.state.owner_delivery_unit },
                                                { title: 'Sector', field: 'sector', lookup: this.state.sector },
                                                { title: 'Start Date', field: 'start_date', type: 'date' },
                                                { title: 'End Date', field: 'end_date', type: 'date' },
                                            ]}
                                            data={this.state.unAllocatedGF}
                                            isLoading={this.state.isLoading}
                                            options={{
                                                headerStyle: {
                                                    fontWeight: 'bold',
                                                },
                                                padding: 'dense',
                                                showTitle: false,
                                                searchFieldAlignment: 'left',
                                                paging: false,
                                                pageSize: 20
                                            }}
                                            onRowClick={(event, rowData, togglePanel) => {
                                                this.postOps({
                                                    ptd_employee: rowData.id,
                                                    discipline: this.props.discipline
                                                })

                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        {/* <IconButton><Done /></IconButton> */}
                                        <IconButton onClick={() => this.setState({ showGFAdd: false })}><Close /></IconButton>
                                    </DialogActions>
                                </Dialog>

                            </Paper>
                        </Box>
                }
            </>
        );
    }
}

export default withSnackbar(withStyles(useStyle)(OHLStaffTables));