import React from 'react';
import MaterialTable from 'material-table';
import RolesGroupTransferList from './RolesGroupTransferList';
import Service from '../../config/networkutils';
import { roles_api, roles_group_api } from '../../config/apiList'
import Auth from '../../utils/authenticate';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';

const token = Auth.token();

class RolesGroup extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            roles: [],
            addGroupName: false,
            groupName: '',
            isLoading: true,
            deleteDialog: false,
            deleteData: {},
            rowData: null
        }
    }

    componentDidMount() {
        this.fetchOps();
        this.fetcRoleGroups()
    }

    fetchOps() {
        Service.get(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let resData = res.data;
                let archive = []; let live = [];
                resData.forEach(role => {
                    if (role.is_active === true) {
                        live.push(role)
                    }
                    else archive.push(role)
                })
                // this.live = live;
                // this.archive = archive;


                this.setState({ roles: live })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    fetcRoleGroups() {
        Service.get(roles_group_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data },() =>{
                    this.setState({
                        isLoading: false
                    })
                })

            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });

            })
    }

    addRoleGroup(name) {
        this.setState({ isLoading: true })
        Service.post(roles_group_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                name: name
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Role group name successfully added', {
                    variant: 'success'
                });
                this.fetcRoleGroups()
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });

            })
    }

    groupNameSubmit = () => {

        this.addRoleGroup(this.state.groupName)
    }

    deleteRoleGroup = () =>{
        Service.get(`/api/role/group/role_group_deletion/?role_group=${this.state.rowData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Role group deleted successfully', {
                    variant: 'success'
                });
                this.setState({
                    deleteDialog: false
                })
                this.fetcRoleGroups()
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });

            })
    }

    render() {

        const handleClose = () => {
            this.setState({ addGroupName: false })
        }

        const handleDeleteDialog = () => {
            this.setState({ deleteDialog: false })
        }

        return (
            <div>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <div>
                        <MaterialTable
                            title="Roles Group"
                            columns={[
                                { title: 'Role Group Name', field: 'name' },
                            ]}
                            data={this.state.data}
                            detailPanel={rowData => {
                                return (
                                    <RolesGroupTransferList roles={this.state.roles} rowData={rowData} />
                                )
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Add Role Group',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        this.setState({ addGroupName: true })
                                    }
                                },
                                rowData =>{
                                   return{
                                     icon: 'delete',
                                     tooltip: 'Delete Role Group',
                                     isFreeAction: true,
                                     onClick: (event) => {
                                        this.setState({
                                            rowData: rowData,
                                            deleteDialog: true
                                        })
                                     }
                                   }
                                }
                            ]}
                            options={{
                                paging: false,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                headerStyle: {
                                    fontWeight: 'bold',
                                },
                                // padding:'dense',
                                search: false
                            }}

                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {


                                        Service.put(roles_group_api + newData.id + "/", {
                                            headers: {
                                                Authorization: "Token " + token,
                                            },
                                            data: newData
                                        })
                                            .then(res => {
                                                this.props.enqueueSnackbar('Role group name successfully updated', {
                                                    variant: 'success'
                                                });
                                                this.fetcRoleGroups()
                                                resolve();
                                            })
                                            .catch(error => {
                                                this.props.enqueueSnackbar('Something went wrong!', {
                                                    variant: 'error'
                                                });

                                                resolve();
                                            })

                                    }),
                                    // onRowDelete: oldData =>
                                    // new Promise((resolve, reject) => {
                                    //     // setTimeout(() => {
                                    //     //     const dataDelete = [...data];
                                    //     //     const index = oldData.tableData.id;
                                    //     //     dataDelete.splice(index, 1);
                                    //     //     setData([...dataDelete]);
                        
                                    //     //     resolve();
                                    //     // }, 1000);
                                    //     // this.setState({
                                    //     //     deleteData: oldData
                                    //     // },() =>{
                                    //     //     this.setState({
                                    //     //         deleteDialog: true
                                    //     //     })
                                    //     // })
                                    //     this.deleteRoleGroup(oldData)
                                    //     resolve()
                                       
                                    // })
                            }}
                        />

                        <Dialog open={this.state.addGroupName} onClose={handleClose}>
                            <DialogTitle>Add Group Name</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Please enter group name in below field to add in table
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="groupName"
                                    label="Group Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={e => this.setState({ groupName: e.target.value })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button color='secondary' onClick={handleClose}>Cancel</Button>
                                <Button onClick={() => {
                                    this.groupNameSubmit();
                                    handleClose()
                                }}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.deleteDialog} onClose={handleDeleteDialog}>
                            <DialogTitle>Delete Role Group</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                   Are you sure. You want to delete the respective Role Group
                                </DialogContentText>
                                {/* <TextField
                                    autoFocus
                                    margin="dense"
                                    id="groupName"
                                    label="Group Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={e => this.setState({ groupName: e.target.value })}
                                /> */}
                            </DialogContent>
                            <DialogActions>
                                <Button color='primary'  onClick={handleDeleteDialog}>Cancel</Button>
                                <Button color='primary'  onClick={() => {
                                    this.deleteRoleGroup();
                                    handleDeleteDialog()
                                }}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </div>
        )
    }
}

export default withSnackbar(RolesGroup)