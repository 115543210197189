import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Service from '../config/networkutils';
import { getCookie } from '../utils/CookieHelper';
import Auth from '../utils/authenticate';
import Users from '../components/Users/Users';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));

class SubContractor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            userAccess: {},
        }
    }


    componentDidMount() {
        this.fetchSubContractor();
        this.fetchUserAccess();
    }

    fetchUserAccess() {
        const user = Auth.user()
        const token = Auth.token()

        // console.log(user)
        Service.get(`/api/access/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    userAccess: res.data.length ? res.data[0] : {}
                });

            })
            .catch(error => {
                // this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Uh oh! Something went wrong. Please try reloading the page or try later.', {
                    variant: 'error',
                    persist: true
                });
            });
    }

    fetchSubContractor = () => {
        this.setState({ isLoading: true })
        Service.get('/api/subcontractor/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    postSubContractor = (data) => {
        if (!data.name) {
            this.props.enqueueSnackbar('Name cannot be Empty', {
                variant: 'warning'
            });
            this.fetchSubContractor();
        } else {
            data.created_by = user.id
            Service.post('/api/subcontractor/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.fetchSubContractor();
                    this.props.enqueueSnackbar('SubContractor added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.fetchSubContractor();
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    putSubContractor = (data) => {
        if (!data.name) {
            this.props.enqueueSnackbar('Name cannot be Empty', {
                variant: 'warning'
            });
            this.fetchSubContractor();
        } else {
            Service.put('/api/subcontractor/' + data.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            }).then(res => {
                // this.fetchSubContractor();
                this.props.enqueueSnackbar('SubContractor edited successfully', {
                    variant: 'success'
                });
            }).catch(error => {
                console.log(error)
                this.fetchSubContractor();
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
    }

    deleteSubContractor = (data) => {
        Service.delete('/api/subcontractor/' + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            // this.fetchSubContractor();
            this.props.enqueueSnackbar('SubContractor deleted successfully', {
                variant: 'success'
            });
        }
        ).catch(error => {
            console.log(error)
            this.fetchSubContractor();
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="SubContractor"
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Description', field: 'description' },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        padding: 'dense',
                        addRowPosition: 'first',
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        this.postSubContractor(newData);
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putSubContractor(newData)
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    // this.setState({ saved: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteSubContractor(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                    detailPanel={rowData => {return(
                        <div>
                           <Users access={this.state.userAccess} subcontractor={rowData} link={false} />
                        </div>
                    )}}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
            </div>
        );
    }
}

export default withSnackbar(SubContractor);