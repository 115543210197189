import React from 'react'
import MaterialTable from 'material-table';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography, Checkbox, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Service from '../../config/networkutils';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { weekStartDates } from '../../utils/betweenDates';
import { roles_crm_emp, prp_roles_api, prp_roles_api_bulk_update } from '../../config/apiList';
import { getCookie } from '../../utils/CookieHelper';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import Tooltip from "@material-ui/core/Tooltip";
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from "notistack";
import { ArchiveOutlined } from '@material-ui/icons';

const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
  customGridStyle: {
    margin: 0,
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px'
  },
  date: {
    margin: 0,
    width: '100%',
    'margin-top': '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    float: 'right'
  },
  rows: {
    backgroundColor: "#EDFAFF",
  },
  leftSticky: {
    zIndex: 99, position: "sticky",
    left: 0,
    background: "#F8F8F8",
    boxShadow: "1px 2px 1px #F8F8F8"
  }
})

// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]
class PRPEmpTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      dataLength: 0,
      start_date: '',
      subTabledata: [],
      showDialog: false,
      customQtyValues: this.props?.customQtyValues,
      selectedRole: {},
      selectedEmployee: {},
      isLoading: false,
      addEmpDialog: false,
      allEmpList: [],
      empColumns: [],
      owner_region: {},
      owner_delivery_unit: {},
      sector: {},
      assign_emp_data: {},
      allEmpObj: {},
      combiRoleEmployee: [],
      type: 1,
      archiveAlert: false,
      archive_emp_data: '',
      assignEmployees: [],
      addQtyDisbaled: false,
      selectedData: [],
      workType: [],
      isTableLoading: true
    }
  }

  componentDidMount() {
    console.log(this.props)
    let allEmp = [];
    allEmp = this.props.employees

    this.getLinkedRolesForWorkType()

    this.setState({
      allEmpList: allEmp,
      owner_delivery_unit: this.props.owner_delivery_unit,
      owner_region: this.props.owner_region,
      sector: this.props.sector
    }, () => {
      this.getModifiedData(this.props.roleData.prp_role)
    })
  }

  getModifiedData(prp_role) {
    let data = [];
    data = prp_role;
    let data2 = []
    data.forEach(ele => {
      if (this.props.selectedEmployees.length > 0) {
        let find = this.props.selectedEmployees.find(role => role.role_id === ele.role_id && role.id === ele.id)
        if (find) {
          ele['is_checked'] = true;
        } else {
          ele['is_checked'] = false
        }
      } else {
        ele['is_checked'] = false
      }

      let set = []
      let totalWeeks = 0;

      for (const item in ele.dates) {
        set.push(item)
        if (moment(item, 'DD/MM/YYYY').isValid()) {
          totalWeeks = Number(totalWeeks) + Number(ele.dates[item])
        }
      }
      ele['total_weeks'] = totalWeeks.toFixed(1)
      let sorted = set.sort(function (a, b) {
        a = a.toString().split('/');
        b = b.toString().split('/');
        return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
      });
      let allDatesQty = { ...ele, ...ele.dates }


      let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
      allDatesQty = { ...allDatesQty, ...st_en_date }

      data2.push(allDatesQty)
    })
    this.setState({
      data: data2,
      isLoading: false
    })
  }

  addCustomField = () => {
    let fileds = [...this.state.customQtyValues]
    let obj = {
      from: this.props.project.contractor_start_date,
      to: this.props.project.contractor_end_date,
      qty: '',
      error: false,
      errorMsg: ''
    }
    fileds.push(obj)
    this.setState({ customQtyValues: fileds })
  }

  open() {
    this.openDialog()
  }

  dialogFunc() {
    this.openDialog()
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  closeDialog = () => {
    this.setState({ showDialog: false })
  }

  addEmpDialogOpen = () => {
    this.setState({ addEmpDialog: true, isTableLoading: true })
    let url = `/api/prp/rolenew/get_customized_emps_list/?crm=${this.props.project.id}&filter_type=others&archive_reason=0&role=${this.props.roleData?.role_id}`
    Service.get(url, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        var ids = res.data.map(emp => emp.id)
        var empList = []
        empList = this.props.employees.filter(emp => ids.includes(emp.id))
        this.setState({ combiRoleEmployee: empList, isTableLoading: false })
      })
      .catch(error => {
        console.log(error)
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      });
    // let roleId;
    // this.props.prpData.forEach(ele => {
    //   if (ele.id === this.state.assign_emp_data.prp) {
    //     roleId = ele.role
    //   }
    // })
    // let role = this.props.allRoles[roleId];
    // let combiRole = ''


    // // this.props.roles.forEach(role => {
    // //     if(roleId === role.id){
    // //         combiRole = role.current_combi_role
    // //     }
    // // })

    // for (let i = 0; i < this.props.roles.length; i++) {
    //   if (role === this.props.roles[i].job_roles) {
    //     combiRole = this.props.roles[i].current_combi_role;
    //     break;
    //   }
    // }

    // let combinedCombiRoles = [];
    // for (let i = 0; i < this.props.roles.length; i++) {
    //   if (combiRole === this.props.roles[i].current_combi_role) {
    //     combinedCombiRoles.push(this.props.roles[i].id)
    //   }
    // }


    // let combiRoleEmps = [];
    // this.state.allEmpList.forEach(emp => {
    //   // filter combi role
    //   if (combinedCombiRoles.includes(emp.job_title)) {
    //     combiRoleEmps.push(emp)
    //   }
    //   if (emp.global_assign === 1) {
    //     combiRoleEmps.push(emp)
    //   }
    // })

    // combiRoleEmps = combiRoleEmps.filter(emp => !emp.end_date || moment(emp.end_date).isAfter(moment().format()))


    // this.setState({ combiRoleEmployee: combiRoleEmps }, () => {
    //   this.setState({ addEmpDialog: true })
    // })
  }

  addEmpDialogClose = () => {
    this.setState({ addEmpDialog: false, prp_id: null })
  }

  getLinkedRolesForWorkType() {
    Service.get('/api/role/worktype/role_work_type_link/', {
      headers: {
        Authorization: "Token " + token,
      },
    }).then(res => {
      let obj = []
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if (element.linked_role_ids.length !== 0) {
          for (let index = 0; index < element.linked_role_ids.length; index++) {
            const element1 = element.linked_role_ids[index];
            if (element1.role_id === this.props.roleData.role) {
              obj.push(element)
            }
          }
        }
      }
      this.setState({ workType: obj })
    }).catch(error => {
      console.log(error)
      this.props.enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      });
    })
  }

  fetchOps() {
    // this.setState({ isLoading: true })
    Service.get(roles_crm_emp + "?is_archive=0&role=" + this.props.roleData.role_id + "&crm=" + this.props.project.id, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let data = [];
        data = res.data;
        let data2 = []
        data.forEach(ele => {
          if (this.props.selectedEmployees.length > 0) {

            // this.props.roleIdArray.map(role => {
            //   if (role.role_id === ele.role_id &&  role.id === ele.id) {
            //     ele['is_checked'] = true;
            //     break
            //   }else{
            //     ele['is_checked'] = false
            //   }
            // })

            let find = this.props.selectedEmployees.find(role => role.role_id === ele.role_id && role.id === ele.id)
            if (find) {
              ele['is_checked'] = true;
            } else {
              ele['is_checked'] = false
            }

          } else {
            ele['is_checked'] = false
          }


          let set = []
          let totalWeeks = 0;

          for (const item in ele.dates) {
            set.push(item)
            if (moment(item, 'DD/MM/YYYY').isValid()) {
              totalWeeks = Number(totalWeeks) + Number(ele.dates[item])
            }
          }
          ele['total_weeks'] = totalWeeks.toFixed(1)
          let sorted = set.sort(function (a, b) {
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
          });
          let allDatesQty = { ...ele, ...ele.dates }


          let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
          allDatesQty = { ...allDatesQty, ...st_en_date }
          // if (ele.shdl_data) {
          //     ele.shdl_data.forEach((dates, i) => {
          //         let alldates = weekStartDates(dates.from, dates.to);
          //         alldates.forEach(date => {
          //             let qty = dates.qty;
          //             allDatesQty = { ...allDatesQty, [date]: qty }
          //         })
          //     })
          // }
          data2.push(allDatesQty)
          // let keys = Object.keys(allDatesQty);
          // var last = keys[keys.length - 1];

        })

        this.setState({
          data: data2,
          dataLength: data2.length,
          // isLoading: false
          // columns: this.props.column
        })
      })
      .catch(error => {

      })
      .finally(e => this.setState({ isLoading: false }))
  }

  handleCustomQty = (e, idx) => {

    let preObj = { ...this.props.customQtyValues[idx] }
    preObj['qty'] = e.target.value;
    let newValues = [...this.props.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  handleCustomToDate = (date, idx) => {
    let preObj = { ...this.props.customQtyValues[idx] }
    preObj['to'] = moment(date).toISOString();
    let newValues = [...this.props.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  handleCustomFromDate = (date, idx, role_id) => {
    let preObj = [...this.props.customQtyValues]
    for (let i = 0; i < preObj.length; i++) {
      if (preObj[i].role_id === role_id) {
        preObj[i]['from'] = moment(date).toISOString();
      }
    }
    // preObj['from'] = moment(date).toISOString();
    let newValues = [...this.props.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  prpBulkEdit(date, shdl_datas) {
    let newDates = Object.keys(date)
    let data = [...this.props.selectedEmployees]
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      // let keys = Object.keshdl_datasys(element.dates);
      let Quantity = shdl_datas[0].qty
      let obj = {}
      for (let i = 0; i < newDates.length; i++) {
        const element = newDates[i];
        obj[element] = Quantity
      }

      element.dates = obj
      element.shdl_data = shdl_datas
    }

    this.setState({ selectedData: data }, () => {

      Service.patch(prp_roles_api_bulk_update, {
        headers: {
          Authorization: "Token " + token,
        },
        data: this.state.selectedData
      })
        .then(res => {

          this.props.enqueueSnackbar('Updated Successfully', {
            variant: 'success'
          });
          this.setState({ addQtyDisbaled: false, selectedData: [] });
          this.closeDialog()
          this.props.reset()
          this.componentDidMount()

          // resolve({ success: true })
        })
        .catch(e => {
          console.error(e)
          this.props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          });
          // reject({ success: false })
        })
    })
  }

  applyCustomQty = () => {
    this.setState({ addQtyDisbaled: true, })
    try {

      let empIdx = null
      for (let i = 0; i < this.state.data.length; i++) {
        const element = this.state.data[i];
        if (this.state.selectedEmployee.id === element.id) {
          empIdx = i
          break
        }
      }
      // let empIdx = this.state.selectedEmployee.id;
      let allEmpData = [...this.state.data];

      let newData = { ...this.state.selectedEmployee };
      let allDatesQty = {};
      let continueToApply = true;
      this.state.customQtyValues.forEach((dates, i) => {
        let alldates = weekStartDates(dates.from, dates.to);

        alldates.forEach((date) => {
          let qty = Number(dates.qty);
          // if (!validQty.includes(qty)) {
          //     dates.error = true;
          //     dates.errorMsg = "Qty must be 0.2, 0.4, 0.5, 0.6, 0.8 or 1";
          //     // return false;
          //     continueToApply = false
          //     // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
          // }
          if (qty > 60) {
            dates.error = true;
            dates.errorMsg =
              "Maximun quantity limit is 60. Please add quantity below 60";
            continueToApply = false;
          } else {
            dates.error = false;
            dates.errorMsg = ""
          }
          if (date in allDatesQty) {
            qty += Number(allDatesQty[date]);
            if (qty > 60) {
              dates.error = true;
              dates.errorMsg =
                "Qty value can not be more than 60/week for " + date;
              // return false;
              continueToApply = false;
            }
            // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
          }

          allDatesQty = { ...allDatesQty, [date]: qty.toFixed(1) };
        });
      });




      this.setState({ customQtyValues: this.state.customQtyValues });

      if (!continueToApply) {
        this.setState({ addQtyDisbaled: false })
        return;
      }

      let shdl_datas = [...this.state.customQtyValues]
      shdl_datas.forEach((schdule, schduleIndex) => {
        if (!schdule['from']) {
          this.props.enqueueSnackbar("Select from date!", {
            variant: "error",
          });
          return
        }
        if (!schdule['to']) {
          this.props.enqueueSnackbar("Select to date!", {
            variant: "error",
          });
          return
        }
      })
      if (this.props.multipleSelect) {
        this.prpBulkEdit(allDatesQty, shdl_datas)
      } else {
        // this.props.isLoading(true);
        // var id = ''
        // if(this.props.multipleSelect){
        //   for (const key in newData) {

        //     let data = { ...changes[key].newData }
        //     for (const key in data) {
        //         if (moment(key, 'DD/MM/YYYY').isValid()) {
        //             data.dates = { ...data.dates, [key]: data[key] }
        //         }
        //     }
        //     finalChanges.push(data)
        // }
        // }
        // prp_roles_api +  this.state.selectedEmployee.id
        // prp_roles_api_bulk_update
        Service.put(prp_roles_api + this.state.selectedEmployee.id + "/", {
          headers: {
            Authorization: "Token " + token,
          },
          data: {
            shdl_data: this.state.customQtyValues,
            employee: this.state.selectedEmployee.employee,
            dates: allDatesQty,
            type: this.state.type,
          },
        })
          .then((res) => {
            this.props.enqueueSnackbar("Qty added successfully", {
              variant: "success",
            });
            newData = { ...newData, ...allDatesQty };
            if (empIdx !== null) {
              allEmpData.splice(empIdx, 1, newData)
              this.setState({ data: allEmpData })
            }
            // allEmpData[empIdx] = newData;
            this.setState({ addQtyDisbaled: false });
            this.closeDialog();
            this.props.refreshRoles();

          })
          .catch((error) => {
            console.log(error)
            this.setState({ addQtyDisbaled: false })
            this.props.enqueueSnackbar("Something went wrong!", {
              variant: "error",
            });
          })
          .finally((e) => {
            // this.props.isLoading(false);
            this.fetchOps();
          });
      }
    } catch (e) {
      this.setState({ addQtyDisbaled: false })
    }
  };

  removeCustomQty = (idx) => {

    let data = [...this.state.customQtyValues];
    data.splice(idx, 1);

    this.setState({ customQtyValues: data })
  }

  postOps(data) {
    Service.post(prp_roles_api, {
      headers: {
        Authorization: "Token " + token,
      },
      data: data,
    })
      .then(res => {
        this.setState({ isLoading: false, })
        this.fetchOps();
        this.props.enqueueSnackbar('Resource added successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      })
  }

  putOps(data) {
    this.setState({ isLoading: true })
    Service.put(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
      data,
    })
      .then(res => {
        this.addEmpDialogClose()
        this.getSingleRoleData()
        this.props.enqueueSnackbar('Resource assigned to role successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
      });
  }

  archivePutOps(data) {
    Service.put(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
      data,
    })
      .then(res => {

        this.setState({
          archiveAlert: false,
          archive_emp_data: ''
        }, () => this.getSingleRoleData())
        // this.props.refreshRoles()
        this.props.enqueueSnackbar('Employee archived successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
        // this.fetchOps();
      });
  }

  deleteOps(data) {
    this.setState({ isLoading: true })
    Service.delete(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      }
    })
      .then(res => {
        this.fetchOps()
        this.props.enqueueSnackbar('Deleted successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        // this.props.enqueueSnackbar('Something went wrong!', {
        //     variant: 'error'
        // });
        // this.fetchOps();
      });
  }

  tableCellRender(role, columnLabel) {
    // returns a lookup names
    if (columnLabel.field === "emp_id") {
      return columnLabel.lookup[role[columnLabel.field]];
    } else if (columnLabel.field === "type") {
      return columnLabel.lookup[role[columnLabel.field]];
    }
    return role[columnLabel.field];
  }

  selectMultipleEmployees(row) {

    let data = [...this.state.data]
    data.find(x => x.id === row.id).is_checked = !row.is_checked
    this.setState({
      data: data
    }, () => {
      let data = []
      for (let index = 0; index < this.state.data.length; index++) {
        const element = this.state.data[index];
        if (element.is_checked) {
          data.push(element)
        }
      }
      this.setState({
        selectedData: data
      }, () => {
        this.props.selectedData(row)
        // this.addCustomField()
      })
      let disabled = false
      for (let index = 0; index < this.state.data.length; index++) {
        const element = this.state.data[index];
        if (element.is_checked) {
          disabled = true
        }
      }
      if (disabled) {
        this.props.disabled(false)
      } else {
        this.props.disabled(true)
      }
    })

  }

  selectMultiple(row) {
    let data = [...this.state.data]
    data.find(x => x.id === row.id).is_checked = !row.is_checked
    this.setState({
      data: data
    })
    if (this.props.selectedData) {
      this.props.selectedData(row)
    }

  }

  editWorkType(e, row) {
    Service.put(`/api/prp/role/${row.id}/`, {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        id: row.id,
        work_type: e.target.value,
      },
    })
      .then((res) => {
        this.props.enqueueSnackbar("Work Type Edited Successfully", {
          variant: "success",
        });

      })
      .catch((error) => {
        console.log(error)
        this.props.enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      })
      .finally((e) => {
        // this.props.isLoading(false);
        this.fetchOps();
      });
  }

  getSingleRoleData = () => {
    Service.get(`/api/prp/template/get_secured_with_role/?crm=${this.props.project.id}&prp_temp=${this.props.roleData.id}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.getModifiedData(res.data.prp_role)
        if (this.props.getSingleRoleData) {
          this.props.getSingleRoleData(res.data)
        }
      })
      .catch(error => {
        console.log(error)
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      });
  }

  render() {
    let searchParams = new URLSearchParams(window.location.search)

    let currentComponent = searchParams.get("dashboardcomp")
    if (currentComponent === 'Secured') {
      currentComponent = 'l6a_e'
    } else if (currentComponent === 'Unsecured') {
      currentComponent = 'l5a_e'
    }
    // const allRoles = this.props.allRoles
    // const roleId = this.props.roleData.role;
    const { classes } = this.props
    return (
      <>
        {this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
          <>
            <TableRow className={classes.rows} >
              <TableCell style={{ textAlign: "center" }}>
                <b>Archive</b>
              </TableCell>

              <TableCell className={classes.rows} style={{
                minWidth: "120px", zIndex: 99, position: "sticky",
                left: 0,
                background: "#F8F8F8",
                textAlign: "center"
              }}>
                <b>Schedules</b>
              </TableCell>

              <TableCell style={{ width: "30px" }}>
                <b>Assign Employee</b>
              </TableCell>

              {this.state.workType.length > 0 ?
                <TableCell ><b>Work Type</b></TableCell> : null}

              {this.props.columns.map((columnLabel, columnLabelIndex) => (
                <TableCell key={columnLabelIndex}
                  style={{ ...columnLabel.headerStyle, width: "100px" }}>
                  <b>{columnLabel.title}</b>
                </TableCell>
              ))}
            </TableRow>

            {this.state.data.map((row, i) => (
              <TableRow
                key={i}
                className={classes.rows}
                onClick={(e) => {
                  this.setState({ row, empTableOpen: true });
                }}>

                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton
                    size='small'
                    aria-label="close"
                    onClick={(event) => {
                      this.setState({ archive_emp_data: row }, () => {
                        this.setState({ archiveAlert: true });
                      });
                    }}>
                    <Tooltip title="Archive Employee">
                      <ArchiveOutlined fontSize='small' />
                    </Tooltip>
                  </IconButton>
                </TableCell>

                <TableCell className={classes.leftSticky} style={{ width: "100px", textAlign: 'center' }}>
                  <IconButton
                    size='small'
                    aria-label="Schedule Dates"
                    disabled={this.props.selectedEmployees.length > 0 ? true : false}
                    onClick={(event) => {
                      // Do save operation
                      if (row.shdl_data) {
                        this.setState({
                          customQtyValues: row.shdl_data,
                        });
                      }
                      this.setState({
                        selectedRole: this.props.roleData,
                        selectedEmployee: row,
                      }, () => {
                        this.props.rowData(row)
                        this.props.openDialog()
                      });
                    }} >
                    <Tooltip title="Schedule Dates" placement="left-start">
                      <TimelineIcon fontSize='small' />
                    </Tooltip>
                  </IconButton>
                  <Checkbox
                    size='small'
                    checked={row.is_checked}
                    onChange={() => this.selectMultiple(row)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>

                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton
                    size='small'
                    aria-label="close"
                    onClick={(event) => {
                      this.setState({ assign_emp_data: row }, () => {
                        this.addEmpDialogOpen();
                      });
                    }} >
                    <Tooltip title="Assign Employee" >
                      <PersonAddIcon fontSize='small' />
                    </Tooltip>
                  </IconButton>
                </TableCell>

                {this.state.workType.length > 0 ?
                  <TableCell width={100}>
                    <FormControl variant="outlined" size="small">
                      <TextField
                        id="wiringorerecting"
                        name="wiringorerecting"
                        value={row.work_type}
                        onChange={(e) => {
                          this.editWorkType(e, row)
                        }}
                        style={{ width: "130px" }}
                        // label="Wiring/Erecting"
                        select
                        variant='outlined'
                        size='small'
                      >
                        {this.state.workType.map((ele, i) => (
                          <MenuItem value={ele.id}>
                            {ele.name}({ele.short_form})
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </TableCell> : null}

                {this.props.columns.map((columnLabel, indx) => (
                  <TableCell
                    key={indx}
                    style={{ ...columnLabel.headerStyle, width: "100px" }}
                  >
                    {this.tableCellRender(row, columnLabel)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        }

        <Dialog open={this.state.addEmpDialog} onClose={this.addEmpDialogClose} maxWidth={"lg"}>
          <DialogTitle id="form-dialog-title">
            Add Employee
            <IconButton aria-label="close" onClick={this.addEmpDialogClose} style={{ position: 'absolute', top: '5px', right: '20px' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>

            <MaterialTable
              title="Employees"
              columns={[
                { title: 'Employee No', field: 'emp_no', },
                { title: 'First Name', field: 'first_name', },
                { title: 'Last Name', field: 'last_name', },
                { title: 'Email Address', field: 'email', },
                { title: 'Role', field: 'job_title', lookup: this.props.allRoles },
                { title: 'Owner Region', field: 'owner_region', lookup: this.state.owner_region },
                { title: 'Owner Delivery Unit', field: 'owner_delivery_unit', lookup: this.state.owner_delivery_unit },
                { title: 'Sector', field: 'sector', lookup: this.state.sector },
                { title: 'Start Date', field: 'start_date', type: 'date' },
                { title: 'End Date', field: 'end_date', type: 'date' },
              ]}
              data={this.state.combiRoleEmployee}
              isLoading={this.state.isTableLoading}
              options={{
                headerStyle: {
                  fontWeight: 'bold',
                },
                padding: 'dense',
                showTitle: false,
                searchFieldAlignment: 'left',
                paging: false,
                pageSize: 20
              }}
              onRowClick={(event, rowData, togglePanel) => {
                this.putOps({
                  emp_id: rowData.id,
                  crm: this.props.project.id,
                  prp: this.props.roleData.id,
                  role: this.props.roleData.role,
                  id: this.state.assign_emp_data.id
                })
              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.addEmpDialogClose} color="primary" variant='contained' size='small'>Cancel</Button>
            {/* <Button color="primary" onClick={this.postOps}>Add</Button> */}
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.archiveAlert} onClose={() => this.setState({ archiveAlert: false })} maxWidth={"lg"}>
          <DialogTitle id="form-dialog-title">
            Archive Employees
            <IconButton aria-label="close" onClick={() => this.setState({ archiveAlert: false })} style={{ position: 'absolute', top: '5px', right: '20px' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 15 }}>

            <Typography style={{ padding: 10 }}>Are you sure to archive this Employee</Typography>

          </DialogContent>
          <DialogActions>
            <Button color="primary" variant='contained' size='small' onClick={() => this.setState({ archiveAlert: false })}>Cancel</Button>
            <Button color="primary" variant='contained' size='small' onClick={() => this.archivePutOps({
              id: this.state.archive_emp_data.id,
              is_archive: 1,
            })}>Archive</Button>
            {/* {this.props.deletePRPAccess[currentComponent] ? <Button color="primary" onClick={() => this.deleteOps({
              id: this.state.archive_emp_data.id,
            })}>Delete</Button> : null} */}
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(withStyles(useStyle)(PRPEmpTable))