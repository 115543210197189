import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import Service from '../../config/networkutils';
import { otherTasks } from '../../config/apiList';
import { withSnackbar } from 'notistack';
import Auth from '../../utils/authenticate';


const token = Auth.token();


class Leaves extends React.Component {
    state = {
        data: [],
        loading: true
    }
    componentDidMount() {
        Service.get(otherTasks, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ data: res.data, loading: false })
        }).catch(error => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }
    postOps(data) {
        Service.post(otherTasks, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        }).then(res => {
            let dupData = [...this.state.data, res.data]
            this.setState({ data: dupData })
            this.props.enqueueSnackbar('Leave created!', { variant: 'success' })

        }).catch(error => {
            this.props.enqueueSnackbar('Leave creation failed !', { variant: 'error' })
        })
    }
    putOps(data) {
        Service.put(otherTasks + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data
        }).then(res => {
            let dupData = [...this.state.data]
            dupData.forEach((row, rowIndex) => {
                if (row.id === data.id) {
                    dupData[rowIndex].name = data.name
                }
            })
            this.setState({ data: dupData })
            this.props.enqueueSnackbar('Leave updated!', { variant: 'success' })
        }).catch(error => {
            this.props.enqueueSnackbar('Leave updation failed !', { variant: 'error' })
        })
    }
    deleteOps(data) {
        Service.delete(otherTasks + data + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            let dupData = [...this.state.data]
            dupData = dupData.filter(row => row.id !== data)
            this.setState({ data: dupData })

            this.props.enqueueSnackbar('Leave Deleted!', { variant: 'success' })

        }).catch(error => {
            this.props.enqueueSnackbar('Leave Deletion failed !', { variant: 'error' })
        })
    }
    render() {
        return (
            <Paper elevation={1}>
                <MaterialTable
                    title={'Leaves'}
                    columns={[{ title: 'Leave', field: 'name' },]}
                    isLoading={this.state.loading}
                    data={this.state.data}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.postOps({ ...newData, type: 0 })

                                    resolve();
                                }, 1000);
                            }),

                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {

                                    this.putOps({ ...newData, type: 0 })

                                    resolve();
                                }, 1000);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {

                                    this.deleteOps(oldData.id)

                                    resolve();
                                }, 1000);
                            })

                    }}

                >

                </MaterialTable>
            </Paper>
        );
    }
}

export default withSnackbar(Leaves);