import React from 'react';
import moment from 'moment';
import { withSnackbar } from "notistack";
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import ListAltIcon from "@material-ui/icons/ListAlt";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from '@material-ui/core/Tooltip';
import Service from "../../../config/networkutils";
import {
    employees_and_projects,
    ptdEmployeesLeave,
    prp_template_api_bulk_update
} from "../../../config/apiList";
import { getCookie } from "../../../utils/CookieHelper";
import {
    weekStartDates,
    weekStartDatesObject,
    changeItToMonday,
} from "../../../utils/betweenDates";
import Box from '@material-ui/core/Box';
import EmployeeProjectsSchedules from "./EmployeeProjectsSchedules";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { Delete, Done } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { findHighestToDate, findLowestFromDate } from '../../../utils/reuseFunctions';

const token = getCookie("ptd_ts_token");
const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }

const useStyles = (theme) => ({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
        }
    },
    root: {
        // width: 'calc(100vw - 45px)',
        padding: '0px',
        marginLeft: '2px',
    },
    container: {
        maxHeight: 440,
    },
    sticky: {
        // position: "sticky",
        // left: 0,
        background: "#F8F8F8",
        boxShadow: "1px 2px 1px grey"
    },
    smallIcon: {
        padding: '5px'
    },
    weekStyles: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    otherTasksBatchNormal: {
        width: '0',
        height: '0',
        borderTop: '15px solid transparent',
        borderBottom: '15px solid transparent',
        borderRight: '15px solid #E8E8E8',
        // position: 'absolute',
        // top: '0px',
        transform: 'rotate(45deg)',
    },
    otherTasksBatchData: {
        width: '0',
        height: '0',
        borderTop: '15px solid transparent',
        borderBottom: '15px solid transparent',
        borderRight: '15px solid red',
        // position: 'absolute',
        // top: '0px',
        transform: 'rotate(45deg)',
    },
    otInput: {
        width: '100%',
        marginTop: '30px'
    },
    blackIcon: {
        // background: '#ff2400',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
        // background: 'red'
        fontSize: '0.9rem',
        position: 'relative',
        top: '2px',
        left: '80%',
    },

    redIcon: {
        fontSize: '0.9rem',
        position: 'relative',
        top: '2px',
        left: '80%',
        color: 'red'
    }
});
class EmployeeRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allEmployeeSchedules: [],
            allRoles: [],
            rolesObj: {},
            allEmp: [],
            empObj: {},
            columns: [],
            allMonths: [],
            selectedEndDate: null,
            selectedStartDate: null,
            templateRoles: [],
            mountEmployeeProjectsSchedulesDialogUI: false,
            selectedEmployee: "",
            employeeSearchField: "",
            employeeSearchFieldRerender: false,
            rawEmployeeSchedules: [],
            tabcelEdit: { empIndex: null, weekIndex: 0, showInputBox: false },
            editedWeekObj: {},
            otherTaskOpen: false,
            OTDescription: '',
            otEmp: '',
            otweekdate: '',
            leaveEditable: false,
        }
        this.handleReload = this.handleReload.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.addAllocation = this.addAllocation.bind(this);
    }

    handleRefresh() {
        this.props.refreshData()
    }

    tableCellStyle = {
        backgroundColor: "#EDFAFF",
    }

    wc_hex_is_light(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        if (brightness > 155) {
            return '#000000'
        } else {
            return '#ffffff'

        }
    }

    updateEmployeeModifiedSchedules = () => {
        Service.patch(prp_template_api_bulk_update, {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.rawEmployeeSchedules,
        })
            .then((res) => {
                // this.props.updateTheUpdatedSchedules(this.state.rawEmployeeSchedules);
                this.props.enqueueSnackbar("Employee schedules Updated!", {
                    variant: "success",
                });
                this.handleTablecellEditClose();
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Updating employee schedules failed!", {
                    variant: "error",
                });
            });
    };

    applyQuantityToEveryWeek = (rawSchedules) => {
        let rawEmployeeScheduless = [...rawSchedules];
        for (
            // Iterating all the projects
            let project = 0;
            project < rawEmployeeScheduless.length;
            project++
        ) {
            let stDat = findLowestFromDate(rawEmployeeScheduless[project]);
            let enDat = findHighestToDate(rawEmployeeScheduless[project]);

            let allMondayDates = weekStartDatesObject(
                stDat.toString(),
                enDat.toString()
            );
            for (
                let shdl = 0;
                shdl < rawEmployeeScheduless[project].shdl_data.length;
                shdl++
            ) {
                // Iterating all the shdls

                for (let monday in allMondayDates) {
                    // Iterating all the monday dates for one shdl

                    if (
                        moment(monday, "DD/MM/YYYY").isSameOrAfter(
                            moment(
                                rawEmployeeScheduless[project].shdl_data[shdl].from.slice(0, 10),
                            )
                        ) &&
                        moment(monday, "DD/MM/YYYY").isSameOrBefore(
                            moment(
                                rawEmployeeScheduless[project].shdl_data[shdl].to.slice(0, 10)
                            )
                        )
                    ) {
                        allMondayDates[monday] =
                            Number(allMondayDates[monday]) +
                            Number(rawEmployeeScheduless[project].shdl_data[shdl].qty);
                    }
                }
            }
            rawEmployeeScheduless[project].dates = allMondayDates;
        }
        this.setState({ rawEmployeeSchedules: rawEmployeeScheduless }, () => {

            this.updateEmployeeModifiedSchedules();
        });
    };

    getEmployeeSchedules(id) {
        Service.get(employees_and_projects + `?is_archive=0&emp_id=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then((res) => {
                this.setState({
                    rawEmployeeSchedules: res.data,
                });
            })
            .catch((error) => {
                this.props.enqueueSnackbar("Getting employee projects failed!", {
                    variant: "error",
                });
            });
    }

    handleTablecellEdit = (e, empIndex, emp) => {
        this.getEmployeeSchedules(emp.employee_id)
        this.setState({ tabcelEdit: { empIndex, showInputBox: true } })
    }

    handleTablecellEditClose = () => this.setState({ tabcelEdit: { empIndex: null, showInputBox: false }, editedWeekObj: {} })

    handleProjectChange = (e, weekDate, crmId) => {
        let prevEditedWeekObj = { ...this.state.editedWeekObj }
        if (prevEditedWeekObj.hasOwnProperty(weekDate)) {

            if (prevEditedWeekObj[weekDate].hasOwnProperty(crmId)) {

                prevEditedWeekObj[weekDate][crmId] = parseFloat(e.target.value)
            } else {
                prevEditedWeekObj[weekDate] = { ...prevEditedWeekObj[weekDate], [crmId]: parseFloat(e.target.value) }

            }
        } else {
            prevEditedWeekObj = { ...prevEditedWeekObj, [weekDate]: { [crmId]: parseFloat(e.target.value) } }

        }

        this.setState({ editedWeekObj: prevEditedWeekObj })
    }

    submitEditedWeek = () => {
        // This function edit the allocated resources and call the functio apply quantity to every week.
        let editedWeekObj = { ...this.state.editedWeekObj }
        let allSchedules = [...this.state.rawEmployeeSchedules]

        for (let weekScheduleDate in editedWeekObj) {

            if (Object.keys(editedWeekObj[weekScheduleDate]).length !== 0) {
                for (let scheduleIdx = 0; scheduleIdx < allSchedules.length; scheduleIdx++) {
                    for (const key in editedWeekObj[weekScheduleDate]) {

                        if (allSchedules[scheduleIdx]["crm"] === parseInt(key)) {
                            for (let projectScheduleIndex = 0; projectScheduleIndex < allSchedules[scheduleIdx]['shdl_data'].length; projectScheduleIndex++) {

                                // Finding Monday date on the week
                                let fromdate = changeItToMonday(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'].slice(0, 10))
                                let todate = changeItToMonday(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'].slice(0, 10))

                                // Resetting to monday date of the week
                                allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'] = fromdate
                                allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'] = todate
                                if (fromdate === todate && fromdate === moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true)) {
                                    // if fromdate === todate and curent iterated date with fromdate or to date then add the quantity to that week
                                    allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['qty'] = editedWeekObj[weekScheduleDate][key]
                                }
                                else {

                                    let allweekdates = weekStartDates(moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'].slice(0, 10)), moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'].slice(0, 10)))
                                    // Otherwise split the already existing schedule and apply the quantity

                                    if (moment(weekScheduleDate, 'DD/MM/YYYY').isSame(moment(fromdate.slice(0, 10)))) {

                                        allSchedules[scheduleIdx]['shdl_data'].push({ to: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true), qty: editedWeekObj[weekScheduleDate][key], from: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true) })
                                        allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'] = moment(allweekdates[allweekdates.indexOf(weekScheduleDate) + 1], 'DD/MM/YYYY').toISOString(true)

                                        // allSchedules[scheduleIdx]['shdl_data'].push({ to: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'], qty: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['qty'], from: moment(allweekdates[allweekdates.indexOf(weekScheduleDate) + 1], 'DD/MM/YYYY').toISOString(true) })
                                        // allSchedules[scheduleIdx]['shdl_data'].splice(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'], 1)
                                        break;
                                    } else if (moment(weekScheduleDate, 'DD/MM/YYYY').isSame(moment(todate.slice(0, 10)))) {

                                        // let allweekdates = weekStartDates(moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'].slice(0, 10)), moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'].slice(0, 10)))

                                        allSchedules[scheduleIdx]['shdl_data'].push({ to: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true), qty: editedWeekObj[weekScheduleDate][key], from: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true) })
                                        allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'] = moment(allweekdates[allweekdates.indexOf(weekScheduleDate) - 1], 'DD/MM/YYYY').toISOString(true)
                                        // allSchedules[scheduleIdx]['shdl_data'].push({ to: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'], qty: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['qty'], from: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'] })
                                        break;
                                    }
                                    else if (moment(weekScheduleDate, 'DD/MM/YYYY').isSameOrAfter(moment(fromdate.slice(0, 10))) && moment(weekScheduleDate, 'DD/MM/YYYY').isSameOrBefore(moment(todate.slice(0, 10)))) {

                                        // let allweekdates = weekStartDates(moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['from'].slice(0, 10)), moment(allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'].slice(0, 10)))
                                        allSchedules[scheduleIdx]['shdl_data'].push({ to: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true), qty: editedWeekObj[weekScheduleDate][key], from: moment(weekScheduleDate, 'DD/MM/YYYY').toISOString(true) })
                                        allSchedules[scheduleIdx]['shdl_data'].push({ to: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'], qty: allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['qty'], from: moment(allweekdates[allweekdates.indexOf(weekScheduleDate) + 1], 'DD/MM/YYYY').toISOString(true) })
                                        allSchedules[scheduleIdx]['shdl_data'][projectScheduleIndex]['to'] = moment(allweekdates[allweekdates.indexOf(weekScheduleDate) - 1], 'DD/MM/YYYY').toISOString(true)
                                        break;
                                    }
                                }

                            }

                        }
                    }
                }
                // apply qty to all week dates
                // call the backend
            }
            else if (Object.keys(editedWeekObj[weekScheduleDate]).length === 0) {

                this.handleTablecellEditClose();
            }
        }
        this.setState({ rawEmployeeSchedules: allSchedules, editedWeekObj: {} }, this.applyQuantityToEveryWeek(allSchedules))
        // this.setState({ rawEmployeeSchedules: allSchedules, editedWeekObj: {} })



    }

    mountEmployeeWorkSchedules = (selectedEmployee, index) => {
        this.setState({ mountEmployeeProjectsSchedulesDialogUI: true });
        this.setState({ selectedEmployee, selectedIndex: index });
    };

    makeReportForOneEmployee(empProjectsArray) {


        const startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(1, 'months').startOf('month');
        const endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(11, 'months').endOf('month');


        let startDate = moment(startOfMonth)//moment('2012-04-01');
        let endDate = moment(endOfMonth)//moment('2014-11-01');

        let result = [];

        if (endDate.isBefore(startDate)) {
            throw new Error("End date must be greated than start date.")
        }

        while (startDate.isBefore(endDate)) {
            result.push(months[startDate.month()] + ' ' + startDate.year());
            startDate.add(1, 'month');
        }

        let allWeekDates = weekStartDates(startOfMonth, endOfMonth);


        let final = []
        let colspan = 1;
        for (let i = 0; i < allWeekDates.length; i++) {

            let date = moment(allWeekDates[i], 'DD/MM/YYYY')
            let monthYear = months[date.month()] + ' ' + date.year()
            if (!result.length) {
                result.push(monthYear)
                final.push({ month: monthYear, colSpan: colspan })
            }
            else {
                if (result[result.length - 1] === monthYear) {
                    colspan += 1
                    final[final.length - 1]['colSpan'] = colspan
                }
                else {
                    colspan = 1
                    result.push(monthYear)
                    final.push({ month: monthYear, colSpan: colspan })
                }
            }

        }

        // let empObj = {}
        let employeeRowData;
        //let newData = { [this.state.allEmp[i].id]: this.state.allEmp[i].first_name + ' ' + this.state.allEmp[i].last_name }
        // empobj = { ...empobj, ...newData }
        let newEle = this.props.emp
        let weeks = [];
        let weekdata = []
        for (let j = 0; j < empProjectsArray.length; j++) {
            // if (this.state.allEmp[i].job_title === this.props.data.id && empProjectsArray[j].role_id === this.props.data.id) {
            // allWeekDates.forEach(date => {
            for (let m = 0; m < allWeekDates.length; m++) {
                let date = allWeekDates[m];
                if (empProjectsArray[j].dates) {
                    if (empProjectsArray[j].dates.hasOwnProperty(date) && empProjectsArray[j].dates[date] !== 0) {
                        if (weeks.indexOf(date) === -1) {
                            newEle = { ...newEle, [date]: empProjectsArray[j].crm }
                            weeks.push(date);
                            weekdata.push({ name: [empProjectsArray[j].crm_name], project_code: [this.props.projectObj[empProjectsArray[j].crm]['project_code']], crmId: [empProjectsArray[j].crm], crm_color_code: [empProjectsArray[j].crm_color_code], })
                        } else {
                            // If already a project in the week then concatenate the date
                            if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                                weekdata[weeks.indexOf(date)]['name'].push(empProjectsArray[j].crm_name)
                                weekdata[weeks.indexOf(date)]['project_code'].push(this.props.projectObj[empProjectsArray[j].crm_id]['project_code'] ? this.props.projectObj[empProjectsArray[j].crm_id]['project_code'] : this.props.projectObj[empProjectsArray[j].crm_id]['crm_name'])
                                weekdata[weeks.indexOf(date)]['crmId'].push(empProjectsArray[j].crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(empProjectsArray[j].crm_color_code)
                                // weekdata[weeks.indexOf(date)] +
                                // "\n , \n" +
                                // this.props.projectObj[empProjectsArray[j].crm];

                            } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                                weekdata[weeks.indexOf(date)]['name'].push(this.props.projectObj[empProjectsArray[j].crm])
                                weekdata[weeks.indexOf(date)]['project_code'].push(this.props.projectObj[empProjectsArray[j].crm_id]['project_code'] ? this.props.projectObj[empProjectsArray[j].crm_id]['project_code'] : this.props.projectObj[empProjectsArray[j].crm_id]['crm_name'])
                                weekdata[weeks.indexOf(date)]['crmId'].push(empProjectsArray[j].crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(empProjectsArray[j].crm_color_code)
                                // weekdata[weeks.indexOf(date)] =
                                //   weekdata[weeks.indexOf(date)] +
                                //   this.props.projectObj[empProjectsArray[j].crm];
                            }
                        }
                    }
                    else {
                        newEle = { ...newEle, [date]: '' }
                        weeks.push(date);
                        weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [] })
                    }
                }


            }
            // }
        }
        newEle['weeks'] = weeks;
        newEle['weekdata'] = weekdata
        // if (this.state.allEmp[i].job_title === this.props.data.id && newEle.weekdata.length > 0) {
        if (newEle.weekdata.length > 0) {
            let notEmpty = newEle.weekdata.filter((week => week['name'].length > 0))
            // if all weekdata strings are empty then fiter it out
            if (notEmpty.length > 1 && moment(newEle.end_date).isAfter(moment().format())) {
                // if (notEmpty.length > 1){
                employeeRowData = newEle
            }
        }
        employeeRowData = newEle
        // let columns = []
        // // columns.push({ title: 'Employee', field: 'id', lookup: empobj })
        // for (let i = 0; i < allWeekDates.length; i++) {
        //   let newObj = {
        //     title: moment(allWeekDates[i], 'DD/MM/YYYY').format('DD MMM'),
        //     field: allWeekDates[i],
        //     type: 'number',
        //     width: 150,
        //     sorting: false,
        //     editable: 'never',
        //     filtering: false
        //   }
        //   columns.push(newObj)
        // }
        this.props.updateEmployeeRow(employeeRowData)
        // this.setState({ allEmpCopy: filterEmp, allEmp: filterEmp, columns, allMonths: final })
        // return employeeRowData
    }

    includeLeaves(leavesArray) {
        let allEmployeeSchedules = [...this.props.emp.weekdata]
        let columns = [...this.props.columns]
        for (let leaveindex = 0; leaveindex < leavesArray.length; leaveindex++) {
            for (let colindex = 0; colindex < columns.length; colindex++) {
                // let date = moment(columns[colindex]['field'], 'DD/MM/YYYY').toISOString().slice(0, 10)
                let date = moment(columns[colindex]['field'], 'DD/MM/YYYY').format('YYYY-MM-DD')
                if (leavesArray[leaveindex]['date'] === date) {
                    allEmployeeSchedules[colindex].leaves = leavesArray[leaveindex]
                }
            }

        }
        return allEmployeeSchedules
    }

    openAddLeaveDialog(weekIndex) {
        this.setState({ selectedOtherTask: '', otherTaskOpen: true, OTDescription: '', otweekdate: this.props.columns[weekIndex]['field'] })
    }

    openEditLeaveDialog(weekIndex, id) {
        this.setState({ selectedOtherTask: this.state.allEmployeeSchedules[weekIndex]['leaves']['other_tasks'], OTDescription: this.state.allEmployeeSchedules[weekIndex]['leaves']['comments'], otherTaskOpen: true, otweekdate: this.props.columns[weekIndex]['field'], leaveEditable: true, leaveEditId: id, editWeekIndex: weekIndex })
    }

    otPostOps() {

        let emp_id = (this.props.emp).hasOwnProperty('employee_id') ? (this.props.emp).employee_id : (this.props.emp).id
        let date = moment(this.state.otweekdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        let data = {
            ptd_employee: emp_id,
            other_tasks: this.state.selectedOtherTask,
            date,
            comments: this.state.OTDescription,
        };

        Service.post(ptdEmployeesLeave + '?user=' + emp_id, {
            headers: {
                Authorization: "Token " + token,
            }, data
        }).then(res => {
            let dupOTs = [...this.state.allLeaves, res.data]
            let revisedSchedules = this.includeLeaves(dupOTs)
            this.setState({ allLeaves: dupOTs, otherTaskOpen: false, allEmployeeSchedules: revisedSchedules, selectedOtherTask: '', OTDescription: '', otweekdate: '' })
            this.props.enqueueSnackbar('Leave created', { variant: 'success' })

        }).catch(error => {
            this.props.enqueueSnackbar('Creating Leave failed retry!', { variant: 'error' })
        })
    }

    otPutOps() {

        let emp_id = (this.props.emp).hasOwnProperty('employee_id') ? (this.props.emp).employee_id : (this.props.emp).id
        let date = moment(this.state.otweekdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        let data = {
            ptd_employee: emp_id,
            other_tasks: this.state.selectedOtherTask,
            date,
            comments: this.state.OTDescription,
        };

        Service.put(ptdEmployeesLeave + this.state.leaveEditId + "/", {
            headers: {
                Authorization: "Token " + token,
            }, data
        }).then(res => {
            let dupOTs = [...this.state.allLeaves, res.data]
            let revisedSchedules = this.includeLeaves(dupOTs)
            this.setState({ allLeaves: dupOTs, otweekdate: '', otherTaskOpen: false, allEmployeeSchedules: revisedSchedules, leaveEditable: false, leaveEditId: '', selectedOtherTask: '', OTDescription: '' })
            this.props.enqueueSnackbar('Leave Updated', { variant: 'success' })

        }).catch(error => {
            this.props.enqueueSnackbar('Updating Leave failed retry!', { variant: 'error' })
        })
    }

    otDelOps() {
        Service.delete(ptdEmployeesLeave + this.state.leaveEditId + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            let dupOTs = [...this.state.allLeaves]
            dupOTs = dupOTs.filter(leav => leav.id !== this.state.leaveEditId)
            let revisedSchedules = [...this.state.allEmployeeSchedules]
            delete revisedSchedules[this.state.editWeekIndex]['leaves']
            this.setState({ allLeaves: dupOTs, otweekdate: '', otherTaskOpen: false, allEmployeeSchedules: revisedSchedules, leaveEditable: false, leaveEditId: '', selectedOtherTask: '', OTDescription: '' })
            this.props.enqueueSnackbar('Leave Deleted', { variant: 'success' })

        }).catch(error => {
            this.props.enqueueSnackbar('Deleting Leave failed retry!', { variant: 'error' })
        })
    }

    componentDidMount() {
        // let templateRoles = this.props.templateData.filter(ele => ele.role_id === this.props.data.id);
        console.log(this.props)
        let { selectedStartDate, selectedEndDate, allEmployeesLeaves, } = this.props;
        let emp_id = (this.props.emp).hasOwnProperty('employee_id') ? (this.props.emp).employee_id : (this.props.emp).id
        let currentEmployeesLeaves = allEmployeesLeaves.filter(leave => leave.ptd_employee === emp_id)
        let allEmployeeSchedules = this.includeLeaves(currentEmployeesLeaves);
        this.setState({ selectedStartDate, selectedEndDate, allEmployeeSchedules, allLeaves: currentEmployeesLeaves })
    }

    handleReload() {
        this.props.refreshData()
    }

    addAllocation(emp, prj) {
        if (this.props.addAllocation) {
            this.props.addAllocation(emp, prj)
            this.setState({ mountEmployeeProjectsSchedulesDialogUI: false })
        }
    }

    render() {

        const classes = this.props.classes;
        const { index, emp, columns } = this.props;

        return (<TableRow key={index} ref={this.childRef}>
            <TableCell colSpan={2} width={50} className={classes.sticky} style={{ position: "sticky", left: '0px', zIndex: 99 }} align="center">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton
                            aria-label="close"
                            variant="outlined"
                            color="primary"
                            onClick={(e) =>
                                this.mountEmployeeWorkSchedules(emp, index)
                            } >
                            <ListAltIcon />
                        </IconButton>
                        {this.state.tabcelEdit['showInputBox'] && this.state.tabcelEdit['empIndex'] === index ?
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <IconButton className={classes.smallIcon} onClick={this.handleTablecellEditClose}>
                                    <CloseIcon />
                                </IconButton>
                                <IconButton className={classes.smallIcon} onClick={this.submitEditedWeek}>
                                    <DoneAllIcon />
                                </IconButton>
                            </Box>
                            : null}
                        {this.state.tabcelEdit['showInputBox'] && this.state.tabcelEdit['empIndex'] === index ? null : <IconButton style={{ padding: '10px' }} onClick={(e) => this.handleTablecellEdit(e, index, emp)}  ><EditIcon /></IconButton>}
                    </Box>

                    <Box >
                        <span >{emp.first_name + ' ' + emp.last_name}</span>
                    </Box>
                </Box>
            </TableCell>
            <TableCell width={100} className={classes.sticky} style={{ position: "sticky", left: '200px', zIndex: 999 }} align="center">
                {emp.role_name+' / '+emp.allocated_role}
            </TableCell>

            {this.state.allEmployeeSchedules.map((aweek, weekIndex) => {
                return <TableCell key={weekIndex} className={classes.root} style={this.state.tabcelEdit['empIndex'] === index ? this.tableCellStyle : { width: '200px', }} >
                    {
                        this.state.tabcelEdit['empIndex'] === index ? <span>{""}</span> :
                            <div>
                                {
                                    aweek['leaves'] ?
                                        <Tooltip title={
                                            <div>
                                                <dl style={{ fontSize: '0.8rem' }}>
                                                    <dt style={{ marginBottom: '5px', fontWeight: 'bold' }}>{this.props.othertaskObject[aweek['leaves'].other_tasks]}</dt>
                                                    <dd>{aweek['leaves'].comments}</dd>
                                                </dl>
                                            </div>}>
                                            <span onClick={() => this.openEditLeaveDialog(weekIndex, aweek['leaves'].id)}>
                                                <AssignmentLateIcon className={classes.redIcon} />
                                            </span>
                                        </Tooltip> :
                                        <Tooltip title={'Create Leave'}>
                                            <span onClick={() => this.openAddLeaveDialog(weekIndex)}>
                                                <AssignmentLateIcon className={classes.blackIcon} />
                                            </span>
                                        </Tooltip>
                                }
                                <div>
                                    {aweek['project_code'].map((pk, pkIndex) =>
                                        <Tooltip key={pkIndex} title={<span style={{ fontSize: '0.8rem' }}>{aweek['name'][pkIndex]}</span>} arrow>
                                            <p style={{ background: aweek['crm_color_code'][pkIndex] ? aweek['crm_color_code'][pkIndex] : "#fff", color: aweek['crm_color_code'][pkIndex] ? this.wc_hex_is_light(aweek['crm_color_code'][pkIndex]) : "#000" }}>{pk ? pk : aweek['name'][pkIndex]}</p>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                    }
                    {this.state.tabcelEdit['showInputBox'] && this.state.tabcelEdit['empIndex'] === index ?
                        <div className={classes.weekStyles}>
                            {aweek['project_code'].map((project, projIndex) => (
                                <Tooltip key={projIndex} placement='right-end' title={<span style={{ fontSize: '0.8rem' }}>{aweek['name'][projIndex]}</span>}>
                                    <TextField
                                        autoComplete='off'
                                        style={{ width: '2rem' }}
                                        key={projIndex}
                                        type="number"
                                        id="standard-basic"
                                        label={project}
                                        placeholder={project ? project : aweek['name'][projIndex]}
                                        onChange={(e) => this.handleProjectChange(e, columns[weekIndex]['field'], aweek['crmId'][projIndex])}
                                    />
                                </Tooltip>
                            ))}
                        </div>
                        : null}
                </TableCell>
            })
            }
            {
                this.state.mountEmployeeProjectsSchedulesDialogUI ? (
                    <EmployeeProjectsSchedules
                        refreshData={this.handleRefresh}
                        reload={this.handleReload}
                        employee={this.state.selectedEmployee}
                        roleId={this.props.role}
                        allProjects={this.props.allProjects}
                        allRoles={this.props.allRoles}
                        liveOpportunities={this.props.liveOpportunities}
                        updateTheUpdatedSchedules={(empArray) =>
                            this.makeReportForOneEmployee(empArray)
                        }
                        employeeUpdate={(updatedEmployeeData) => {
                            this.setState({
                                selectedIndex: "",
                                mountEmployeeProjectsSchedulesDialogUI: false,
                            });
                        }}
                        unmountMe={() => {
                            this.setState({
                                ...this.state,
                                mountEmployeeProjectsSchedulesDialogUI: false,
                                selectedEmployee: "",
                            });
                        }}
                        addAllocation={this.addAllocation}
                    />
                ) : null
            }
            < Dialog
                open={this.state.otherTaskOpen} onClose={() => this.setState({ otherTaskOpen: false })}>
                <DialogTitle>{'Add Other Task'}</DialogTitle>
                <DialogContent>

                    <FormControl required variant="filled" className={classes.otInput}>
                        <InputLabel id="demo-simple-select-outlined-label">Other Tasks</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            value={this.state.selectedOtherTask}
                            onChange={(e) => this.setState({ selectedOtherTask: e.target.value })}
                            autoWidth={true}
                        >
                            <MenuItem value=''>None</MenuItem>
                            {this.props.allOtherTasks.map((task, taskIndex) =>
                                <MenuItem key={taskIndex} value={task.id}>{task.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.otInput}
                        id="description"
                        label="Description"
                        value={this.state.OTDescription}
                        onChange={(e) => this.setState({ OTDescription: e.target.value })}
                        variant="outlined"
                        fullWidth={true}
                        multiline
                        rows={5}
                    />

                </DialogContent>
                <DialogActions>
                    {this.state.leaveEditable ? <>
                        <IconButton onClick={(e) => this.otPutOps()}><Done /></IconButton> <IconButton onClick={(e) => this.otDelOps()}><Delete /></IconButton>
                    </>
                        : <IconButton onClick={(e) => this.otPostOps()}><Done /></IconButton>}

                </DialogActions>
            </Dialog >
        </TableRow >
        )
    }
}

export default withSnackbar(withStyles(useStyles)(EmployeeRow));