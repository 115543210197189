import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Checkbox, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { DateTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { Component } from 'react'
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import { withSnackbar } from 'notistack';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CoffeeLoading } from 'react-loadingg';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete } from '@material-ui/lab';
import BusinessIcon from '@material-ui/icons/Business';
import MaterialTable from 'material-table';
import Auth from '../../../utils/authenticate';
import CountryCode from '../../CRM/CountryCode';
import CloseIcon from '@material-ui/icons/Close';



const token = getCookie("ptd_ts_token")
const user = Auth.user()

const typeOptions = [{ id: 1, name: 'Site Contact', key: 'site_contact' }, { id: 2, name: 'Site CC', key: 'site_cc' }]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ReportingInstructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().weekday(1).format('YYYY-MM-DD'),
            tableData: [],
            copyTableData: [],
            tableDataLoading: false,
            selectedEmployees: [],
            rowID: [],
            coffeeLoading: false,
            filters: {
                crm_id: '',
                crm: ''
            },
            templateDialog: false,
            templateData: [],
            editTemplateData: {
                'template': '',
                'site_address': ''
            },
            dialogTitle: '',
            configData: [],
            configDialog: false,
            contactDialog: false,
            contactData: [],
            newContactData: {
                'type': '',
                'contact': []
            },
            users: [],
            selectedOpportunity: {},
            disabled: false,
            contactType: 'add',
            reportingDate: null,
            travelDate: null,
            instructions: '',
            siteAddressDialog: false,
            newSiteAddress: '',
            changeSiteAddressDialog: false,
            selectSiteAddressDialog: false,
            preview: true,
            contactTableLoading: false,
            siteAddressTableLoading: false,
            selectedConfig: {},
            configtableLoading: false
        }
        this.countryCodeRef = React.createRef()
        this.getCountryCode = this.getCountryCode.bind(this)
    }

    getData = () => {
        if (moment(this.state.date).isoWeekday() !== 1) {
            this.props.enqueueSnackbar('Please select Monday', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({ tableDataLoading: true })
            Service.get(`/api/role/schedule/get_newstarters_emp_data/?monday_date=${this.state.date}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        element.open = false
                        element.disabled = true
                        for (let i = 0; i < element.new_starters.length; i++) {
                            const emp = element.new_starters[i];
                            emp.is_selected = false
                        }
                        console.log(this.state.rowID)
                        if (this.state.rowID.includes(element.crm_id)) {
                            element.open = true
                        }
                    }

                    this.setState({
                        tableData: res.data,
                        tableDataLoading: false,
                        copyTableData: res.data
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    selectEmployees = (employee, row) => {
        employee.is_selected = !employee.is_selected
        let selectedEmployees = row.new_starters.filter(emp => emp.is_selected)
        if (selectedEmployees.length > 0) {
            row.disabled = false
            this.setState({ selectedEmployees: selectedEmployees })
        } else {
            row.disabled = true
            this.setState({ selectedEmployees: [] })
        }
        this.setState({ tableData: this.state.tableData })
    }

    emailInstructions = (ele) => {
        if (this.state.reportingDate === null) {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        }
        else if (this.state.reportingDate < this.state.travelDate) {
            this.props.enqueueSnackbar('Reporting Date should be greater than Travel Date', {
                variant: 'warning'
            });
            return
        }
        else {
            this.setState({ coffeeLoading: true })
            let selectedEmployeesID = this.state.selectedEmployees.map(emp => emp.role_schedule)
            console.log(this.state.configData)
            let data = {
                'role_schedule': selectedEmployeesID,
                // 'travel_date': moment(this.state.travelDate).format('DD-MM-YYYY'),
                'reporting_date': moment(this.state.reportingDate).format('DD-MM-YYYY HH:mm:ss'),
                'preview_only': this.state.preview,
                'config_id': this.state.selectedConfig.id
            }
            if (this.state.travelDate) {
                data.travel_date = moment(this.state.travelDate).format('DD-MM-YYYY')
            }
            if (this.state.instructions.length > 0) {
                data.comments = this.state.instructions
            }
            Service.post(`/api/role/schedule/generate_reporting_instruction_report/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    if (this.state.preview) {
                        this.props.enqueueSnackbar('Preview sent successfully!', {
                            variant: 'success'
                        });
                    } else {
                        this.props.enqueueSnackbar('Email sent successfully!', {
                            variant: 'success'
                        });
                    }
                    this.setState({ coffeeLoading: false, datesDialog: false, selectSiteAddressDialog: false }, () => {
                        this.setState({
                            travelDate: null,
                            reportingDate: null,
                            instructions: '',
                        })
                    })
                    this.getData()
                })
                .catch(error => {
                    console.log(error);
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    handleFilterData = () => {
        const filteredData = this.state.copyTableData.filter((row) => {
            // If all filters are empty, return true for all rows
            const allFiltersEmpty = Object.values(this.state.filters).every(value => value === '');
            if (allFiltersEmpty) {
                return true;
            }
            // Otherwise, check if all filters match
            return Object.keys(this.state.filters).every(key => {
                if (this.state.filters[key].length > 0 && typeof row[key] === 'string') {
                    return row[key].toLowerCase().includes((this.state.filters[key].toLowerCase()));
                } else if (this.state.filters[key].length > 0 && typeof row[key] === 'number') {
                    return row[key].toString().includes(this.state.filters[key].toString())
                }
                // If filter is empty, return true
                return true;
            });
        });
        this.setState({
            tableData: filteredData
        })
    }

    handleFilterChange = (e, type) => {
        let filters = this.state.filters
        filters[e.target.id ? e.target.id : e.target.name] = e.target.value
        this.setState({
            filters: filters
        }, () => {
            this.handleFilterData()
        })

    }

    getFilterColumns = (column) => {
        return <TextField
            type={column.type ? column.type : 'text'}
            id={column}
            value={this.state.filters[column]}
            variant="standard"
            onChange={(e) => this.handleFilterChange(e)}
            InputProps={{
                startAdornment: column.type !== 'date' ? (
                    <InputAdornment position="start">
                        <FilterListIcon fontSize='small' />
                    </InputAdornment>
                ) : null,
            }}
        />
    }

    createNewContact = () => {
        if (this.state.newContactData.contact.length === 0 || this.state.newContactData.key === '') {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            let contact_id = []
            for (let i = 0; i < this.state.newContactData.contact.length; i++) {
                contact_id.push(this.state.newContactData.contact[i].id)
            }
            Service.post('/api/reporting/information/contact/add_multiple_contact/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'config_id': this.state.selectedConfig.id,
                    'type': this.state.newContactData.type.key,
                    'contact': contact_id,
                    'created_by': user.id,
                    'crm': this.state.selectedOpportunity.crm_id
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Added Contact Successfully', {
                        variant: 'success'
                    });
                    this.setState({ contactDialog: false }, () => {
                        this.getContactData()
                        this.getConfigData()
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    createNewConfig = () => {
        if (Object.keys(this.state.editTemplateData).length === 0 || this.state.editTemplateData.hasOwnProperty('template') === false || this.state.editTemplateData.site_address === "") {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        }
        else {
            this.setState({
                disabled: true
            })
            Service.post(`/api/reporting/information/config/?crm=${this.state.selectedOpportunity.crm_id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'crm': this.state.selectedOpportunity.crm_id,
                    'template': this.state.editTemplateData.template.id,
                    'site_address': this.state.editTemplateData.site_address,
                    'created_by': user.id,
                    'template_id': this.state.editTemplateData.template.id
                }
            })
                .then(res => {
                    this.setState({ templateDialog: false, disabled: false }, () => {
                        this.getConfigData(true)
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    getTemplateData(rowData) {
        Service.get('/api/reporting/instruction/template/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let editTemplate = {}
                if (rowData) {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        if (element.id === rowData.template) {
                            editTemplate = {
                                'template': element,
                                'site_address': rowData.site_address
                            }
                        }
                    }
                }
                this.setState({
                    templateData: res.data,
                    editTemplateData: editTemplate,
                    templateDialog: true
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    getContactData() {
        this.setState({
            contactTableLoading: true
        })
        Service.get(`/api/reporting/information/contact/?config__crm=${this.state.selectedOpportunity.crm_id}&config=${this.state.selectedConfig.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    contactData: res.data,
                    contactTableLoading: false,
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    getConfigData(openDialog) {
        this.setState({
            siteAddressTableLoading: true
        })
        Service.get(`/api/reporting/information/config/?crm=${this.state.selectedOpportunity.crm_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                console.log(res.data)
                if (res.data.length === 0) {
                    this.setState({
                        dialogTitle: 'Add Template and Site Address'
                    })
                    this.getTemplateData()
                    if (Object.keys(this.state.selectedConfig).length > 0) {
                        this.getContactData()
                    }
                } else {
                    this.setState({
                        configData: res.data,
                        siteAddressTableLoading: false
                    }, () => {
                        if (openDialog) {
                            this.setState({
                                siteAddressDialog: true
                            })
                        }
                    })
                }
            })
            .catch(e => {
                console.error(e)
            })
    }

    editTemplateData = (rowData) => {
        this.setState({
            dialogTitle: 'Edit Template/ Site Address',
        }, () => {
            this.getContactData()
            this.getTemplateData(rowData)
        })
    }

    editConfig = () => {
        if (Object.keys(this.state.editTemplateData).length === 0 || this.state.editTemplateData.hasOwnProperty('template') === false || this.state.editTemplateData.site_address === "") {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({
                disabled: true
            })
            Service.patch(`/api/reporting/information/config/${this.state.configData[0].id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'template': this.state.editTemplateData.template.id,
                    'site_address': this.state.editTemplateData.site_address,
                    'created_by': user.id,
                    'template_id': this.state.editTemplateData.template.id
                }
            })

                .then(res => {
                    this.setState({ templateDialog: false, disabled: false }, () => {
                        this.getConfigData()
                    })
                }
                )
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                }
                )
        }
    }

    updateContact = () => {
        if (this.state.newContactData.contact.length === 0 || this.state.newContactData.key === '' || (this.state.newContactData.contact_number === null || this.state.newContactData.contact_country_code === null)) {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({
                contactTableLoading: true
            })
            Service.patch(`/api/reporting/information/contact/${this.state.newContactData.id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    'type': this.state.newContactData.type.key,
                    'contact_number': this.state.newContactData.contact_number,
                    'contact_country_code': this.state.newContactData.contact_country_code,
                    'contact': this.state.newContactData.contact.id,
                    'first_name': this.state.newContactData.contact.first_name,
                    'last_name': this.state.newContactData.contact.last_name,
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Updated Contact Successfully', {
                        variant: 'success'
                    });
                    this.setState({
                        newContactData: { type: '', contact: [] },
                        contactDialog: false
                    }, () => {
                        this.getContactData()
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
        }
    }

    deleteContact = (oldData) => {
        Service.delete(`/api/reporting/information/contact/${oldData.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Contact Successfully', {
                    variant: 'success'
                });
                this.getContactData()
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    deleteConfig = (oldData) => {
        this.setState({
            siteAddressTableLoading: true
        })
        Service.delete(`/api/reporting/information/config/${oldData.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Site Address Successfully', {
                    variant: 'success'
                });
                this.getConfigData()
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }


    getUsers = () => {
        Service.get('/api/ptd/employees/employee_data/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    users: res.data
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }


    componentDidMount() {
        this.getUsers()
    }

    getCountryCode(v) {
        this.setState({
            newContactData: {
                ...this.state.newContactData,
                contact_country_code: v.phone
            }
        }, () => {
            if (this.countryCodeRef && this.countryCodeRef.current) {
                this.countryCodeRef.current.setCountryCodeRef(v)
            }
        })
    }

    submitSiteAddress = () => {
        this.setState({
            siteAddressTableLoading: true
        })
        Service.post(`/api/reporting/information/config/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                'crm': this.state.selectedOpportunity.crm_id,
                'site_address': this.state.newSiteAddress,
                'created_by': user.id,
                'template': this.state.selectedConfig.template
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Site Address changed successfully', {
                    variant: 'success'
                });
                this.setState({ changeSiteAddressDialog: false }, () => {
                    this.getConfigData()
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    editSiteAddress = () => {
        this.setState({
            siteAddressTableLoading: true
        })

        Service.patch(`/api/reporting/information/config/${this.state.selectedConfig.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                'site_address': this.state.newSiteAddress
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Site Address changed successfully', {
                    variant: 'success'
                });
                this.setState({ changeSiteAddressDialog: false }, () => {
                    this.getConfigData()
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    submitReportingInstructions = (previewValue, ele) => {
        if (this.state.reportingDate === null) {
            this.props.enqueueSnackbar('Please fill all the fields', {
                variant: 'warning'
            });
            return
        }
        else if (this.state.reportingDate < this.state.travelDate) {
            this.props.enqueueSnackbar('Reporting Date should be greater than Travel Date', {
                variant: 'warning'
            });
            return
        } else {
            this.setState({
                preview: previewValue,
            }, () => {
                this.emailInstructions()
            })
        }
    }



    render() {

        const handleClose = () => {
            this.setState({ datesDialog: false, travelDate: null, reportingDate: null, instructions: '' })
        }

        const handleChangeSiteAddressDialog = () => {
            this.setState({ changeSiteAddressDialog: false })
        }


        return (
            <div>
                <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            label="Date"
                            format='dd-MM-yyyy'
                            minDate={moment().weekday(1).format('YYYY-MM-DD')}
                            value={this.state.date}
                            style={{ width: '200px' }}
                            shouldDisableDate={(date) => date.getDay() !== 1}
                            onChange={(date) => {
                                this.setState({ date: moment(date).format('YYYY-MM-DD') })
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        // className={classes.date}
                        />
                    </MuiPickersUtilsProvider>
                    <Box marginTop="5px" marginLeft="3px">
                        <Button color="primary" size='small' variant='contained' onClick={() => this.getData()}>
                            Submit
                        </Button>
                    </Box>
                </Box>
                {this.state.tableDataLoading ? <Box display='flex' justifyContent='center' alignItems='center' marginTop='20px'><CircularProgress /></Box> :
                    <div>
                        <Box marginTop="20px">
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Actions</TableCell>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell align="center">CRM</TableCell>
                                            <TableCell align="center">Email Status Ratio</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align='center'>{this.getFilterColumns('crm_id')}</TableCell>
                                            <TableCell align='center'>{this.getFilterColumns('crm')}</TableCell>
                                            <TableCell align='center'>{this.getFilterColumns('email_status_ratio')}</TableCell>
                                        </TableRow>
                                        {this.state.tableData.map((row) => (
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        <Box display='flex' flexDirection='row'>
                                                            <IconButton aria-label="expand row" size="small"
                                                                onClick={() => {
                                                                    row.open = !row.open;
                                                                    this.setState({ selectedOpportunity: row }, () => {
                                                                        // this.getConfigData()
                                                                    })
                                                                    this.setState({ tableData: this.state.tableData, rowID: row.open ? [...this.state.rowID, row.crm_id] : this.state.rowID.filter(id => id !== row.crm_id) })
                                                                }}>
                                                                {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                            {this.props.access && this.props.access.access && this.props.access.access.l10a && this.props.access.access.l10a.l10a_b ?
                                                                <Button variant="contained" color="primary" disabled={row.disabled} onClick={() => {this.getConfigData();this.setState({ selectSiteAddressDialog: true })}}>Email Reporting Instructions</Button> : null}
                                                            {this.props.access && this.props.access.access && this.props.access.access.l1a && this.props.access.access.l1a.l1a_g ?
                                                                <IconButton aria-label="expand row" size="small" onClick={() => {
                                                                    this.setState({ selectedOpportunity: row, siteAddressType: "add" }, () => {
                                                                        // this.getContactData()
                                                                        this.getConfigData(true)
                                                                    })
                                                                }}>
                                                                    <Tooltip title='Site Schedule'><BusinessIcon color='primary' />
                                                                    </Tooltip>
                                                                </IconButton> : null}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">{row.crm_id}</TableCell>
                                                    <TableCell align="center">{row.crm}</TableCell>
                                                    <TableCell align="center">{row.email_status_ratio}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={row.open} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Employees
                                                                </Typography>
                                                                <Table size="small" aria-label="purchases">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell />
                                                                            <TableCell align="center">Name</TableCell>
                                                                            <TableCell align="center">Role</TableCell>
                                                                            <TableCell align="center">From Date</TableCell>
                                                                            <TableCell align="center">To Date</TableCell>
                                                                            <TableCell align="center">Qty</TableCell>
                                                                            <TableCell align="center">Email Status</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {row.new_starters.map((employee) => (
                                                                            <TableRow>
                                                                                <TableCell align="center">
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={employee.is_selected}
                                                                                                onChange={() => { this.selectEmployees(employee, row) }}
                                                                                                name="checkedB"
                                                                                                color="primary"
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell align="center">{employee.emp_name}</TableCell>
                                                                                <TableCell align="center">{employee.role_name}</TableCell>
                                                                                <TableCell align="center">{moment(employee.from_date).format("DD-MM-YYYY")}</TableCell>
                                                                                <TableCell align="center">{moment(employee.to_date).format("DD-MM-YYYY")}</TableCell>
                                                                                <TableCell align="center">{employee.qty}</TableCell>
                                                                                <TableCell align="center">{employee.email_status ? moment(employee.email_status).format('DD/MM/YYYY HH:mm:ss') : null}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>}
                <Dialog maxWidth="sm" TransitionComponent={Transition} open={this.state.coffeeLoading} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog
                    TransitionComponent={Transition}
                    maxWidth={'xl'}
                    open={this.state.configDialog}
                    onClose={() => this.setState({ configDialog: false })}
                >
                    {/* <DialogTitle id="alert-dialog-title">Move to live</DialogTitle> */}
                    <DialogContent>
                        <Box marginBottom={'10px'}>
                            <Box display={'flex'} flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
                                <Box display='flex' flexDirection='column'>
                                    <Box display={'flex'} flexDirection='row'>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{'Template: '}</Typography><Typography variant="body1">{this.state.selectedConfig?.template_name}</Typography>
                                    </Box>
                                    <Box display={'flex'} flexDirection='row'>
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>{'Site Address: '}</Typography><Typography variant="body1">{this.state.selectedConfig?.site_address}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <MaterialTable
                            title="Contact"
                            data={this.state.contactData}
                            isLoading={this.state.contactTableLoading}
                            columns={[
                                {
                                    title: 'Contact',
                                    field: 'first_name',
                                    render: rowData => rowData.first_name + ' ' + rowData.last_name,
                                },
                                { title: 'Contact Type', field: 'type', lookup: { 'site_contact': 'Site Contact', 'site_cc': 'Site CC' } },
                                { title: 'Country Code', field: 'contact_country_code' },
                                { title: 'Contact Number', field: 'contact_number' },
                            ]}
                            options={{
                                headerStyle: {
                                    fontWeight: 'bold',
                                },
                                filtering: true,
                                padding: 'dense',
                                paging: false
                            }}
                            actions={[
                                ({
                                    icon: 'add',
                                    tooltip: 'Add Contact',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        this.setState({ newContactData: { type: '', contact: [] }, contactType: 'add', }, () => {
                                            this.setState({ contactDialog: true })
                                        })
                                    }
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => {
                                        console.log(rowData)
                                        let contact = this.state.users.filter(ele => rowData.contact === ele.id)
                                        let type = typeOptions.find(ele => rowData.type === ele.key)
                                        this.setState({
                                            newContactData: {
                                                type: type,
                                                contact: contact,
                                                id: rowData.id,
                                                contact_country_code: rowData.contact_country_code,
                                                contact_number: rowData.contact_number
                                            }
                                            , contactType: 'edit'
                                        }, () => {
                                            this.setState({ contactDialog: true })
                                        })
                                    }
                                })
                            ]}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        this.deleteContact(oldData)
                                        resolve();
                                    })
                            }}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    TransitionComponent={Transition}
                    maxWidth={'xl'}
                    open={this.state.siteAddressDialog}
                    onClose={() => this.setState({ siteAddressDialog: false })}
                >
                    <DialogContent>
                        <MaterialTable
                            title="Site Address"
                            data={this.state.configData}
                            isLoading={this.state.siteAddressTableLoading}
                            columns={[
                                { title: 'Template Name', field: 'template_name' },
                                { title: 'Site Address', field: 'site_address' },
                            ]}
                            options={{
                                headerStyle: {
                                    fontWeight: 'bold',
                                },
                                filtering: true,
                                padding: 'dense',
                                paging: false
                            }}
                            onRowClick={(event, rowData) => { this.setState({ selectedConfig: rowData }, () => { this.getContactData() }); this.setState({ configDialog: true }) }}
                            actions={[
                                ({
                                    icon: 'add',
                                    tooltip: 'Add Contact',
                                    isFreeAction: true,
                                    onClick: (event) => {
                                        this.setState({ siteAddressType: 'add', newSiteAddress: '', changeSiteAddressDialog: true })
                                    }
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => {
                                        this.setState({
                                            newSiteAddress: rowData.site_address,
                                            selectedConfig: rowData,
                                            changeSiteAddressDialog: true,
                                            siteAddressType: 'edit'
                                        })
                                    }
                                })
                            ]}
                        // editable={{
                        //     onRowDelete: oldData =>
                        //         new Promise((resolve, reject) => {
                        //             // this.setState({
                        //             //     selectedConfig: rowData,
                        //             // },() =>{
                        //                 this.deleteConfig(oldData)
                        //             // })
                        //             resolve();
                        //         })
                        // }}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.templateDialog}
                    onClose={() => this.setState({ templateDialog: false })}
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                            <Autocomplete
                                options={this.state.templateData}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                defaultValue={this.state.editTemplateData.template}
                                onChange={(e, v) => {
                                    if (v)
                                        this.setState({ editTemplateData: { ...this.state.editTemplateData, template: v } })
                                }}
                                renderInput={(params) => <TextField {...params} label="Template" variant="outlined" />}
                            />
                            <Box marginTop={"10px"}>
                                <TextField
                                    variant='outlined'
                                    label='Site Address'
                                    defaultValue={this.state.editTemplateData.site_address}
                                    style={{ width: 300 }}
                                    onChange={(e) => {
                                        this.setState({ editTemplateData: { ...this.state.editTemplateData, site_address: e.target.value } })
                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({
                            templateDialog: false,
                            editTemplateData: {
                                'template': '',
                                'site_address': ''
                            }
                        })} color="primary">
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.disabled}
                            onClick={() => { if (this.state.dialogTitle === 'Add Template and Site Address') { this.createNewConfig() } else { this.editConfig() } }}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.contactDialog}
                    onClose={() => this.setState({ contactDialog: false })}
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title">{this.state.contactType === "add" ? 'Add New Contact' : 'Edit Contact'}</DialogTitle>
                    <DialogContent>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                            <Autocomplete
                                options={[{ id: 1, name: 'Site Contact', key: 'site_contact' }, { id: 2, name: 'Site CC', key: 'site_cc' }]}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 300 }}
                                value={this.state.newContactData?.type}
                                onChange={(e, v) => {
                                    if (v)
                                        this.setState({ newContactData: { ...this.state.newContactData, type: v } })
                                }}
                                renderInput={(params) => <TextField {...params} label="Contact Type" variant="outlined" />}
                            />

                            <Box marginTop={"10px"}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={this.state.users}
                                    getOptionLabel={(option) => option.last_name + ', ' + option.first_name}
                                    style={{ width: 300 }}
                                    value={this.state.newContactData?.contact}
                                    onChange={(e, v) => {
                                        if (v)
                                            this.setState({ newContactData: { ...this.state.newContactData, contact: v } })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Contact" variant="outlined" />}
                                />
                            </Box>
                            {this.state.contactType === 'edit'
                                //  && this.state.newContactData?.type.key === 'site_contact'
                                ?
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                                    <Box marginTop={"10px"}>
                                        <CountryCode
                                            ref={this.countryCodeRef}

                                            newContactData={this.state.newContactData} handleCountryCode={this.getCountryCode} />

                                    </Box>
                                    <Box marginTop="10px">
                                        <TextField
                                            label='Phone Number'
                                            variant='outlined'
                                            style={{ width: 300 }}
                                            defaultValue={this.state.newContactData.contact_number}
                                            onChange={(e) => this.setState({ newContactData: { ...this.state.newContactData, contact_number: e.target.value } })}
                                        />
                                    </Box>
                                </Box> : null}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ contactDialog: false, newContactData: { type: '', contact: [] } })} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.state.contactType === 'add' ? this.createNewContact() : this.updateContact() }} color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.changeSiteAddressDialog} onClose={handleChangeSiteAddressDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{this.state.siteAddressType === 'add' ? 'Add Site Address' : 'Edit Site Address'}</DialogTitle>
                    <DialogContent>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection="column">
                            <Box marginTop={"10px"}>
                                <TextField
                                    variant='outlined'
                                    label='Site Address'
                                    value={this.state.newSiteAddress}
                                    style={{ width: 300 }}
                                    onChange={(e) => {
                                        this.setState({ newSiteAddress: e.target.value })
                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleChangeSiteAddressDialog} color="primary" variant='contained'>
                            Cancel
                        </Button>
                        <Button onClick={() => { this.state.siteAddressType === 'add' ? this.submitSiteAddress() : this.editSiteAddress() }} color="primary" variant='contained' autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog maxWidth="sm" TransitionComponent={Transition} fullWidth={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={this.state.datesDialog}>
                    <DialogTitle id="customized-dialog-title">
                        <Box display={'flex'} flexDirection={'row'} alignItems='center'>
                            <Typography variant='h6' style={{ flexGrow: 1 }}>{"Select Dates"}</Typography>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon color='error' />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                            <Box display='flex' justifyContent={'space-around'} alignItems={'center'} width="100%">
                                {this.state.selectedConfig?.template_key === 'rob_template' ?
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            margin="normal"
                                            label="Travelling Date"
                                            format='dd-MM-yyyy'
                                            minDate={moment(this.state.date).subtract(2, 'weeks').format('YYYY-MM-DD')}
                                            value={this.state.travelDate}
                                            style={{ width: '250px' }}
                                            onChange={(date) => {
                                                this.setState({ travelDate: moment(date).format('YYYY-MM-DD') })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider> : null}
                                {this.state.selectedConfig?.template_key === 'rob_template' ?
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            label="Reporting Date"
                                            style={{ width: '250px' }}
                                            minDate={this.state.travelDate ? moment(this.state.travelDate).format('YYYY-MM-DD') : ''}
                                            maxDate={this.state.travelDate ? moment(this.state.travelDate).add(2, 'weeks').format('YYYY-MM-DD') : ''}
                                            value={this.state.reportingDate}
                                            onChange={(date) => { this.setState({ reportingDate: date }) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </MuiPickersUtilsProvider> :
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            label="Reporting Date"
                                            style={{ width: '250px' }}
                                            value={this.state.reportingDate}
                                            onChange={(date) => { this.setState({ reportingDate: date }) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </MuiPickersUtilsProvider>}

                            </Box>
                            <Box marginTop={"10px"} width="100%">
                                <TextField
                                    variant='outlined'
                                    multiline
                                    rows={4}
                                    fullWidth
                                    label='Comments'
                                    value={this.state.instructions}
                                    onChange={(e) => this.setState({ instructions: e.target.value })}
                                />
                            </Box>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button size='small' variant='contained' onClick={() => this.submitReportingInstructions(true)} color="primary">
                            Preview
                        </Button>
                        <Button size='small' variant='contained' onClick={() => this.submitReportingInstructions(false)} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.selectSiteAddressDialog} onClose={() => this.setState({ selectSiteAddressDialog: false })}>
                    <DialogTitle>
                        Select the Site Address
                    </DialogTitle>
                    <DialogContent>
                        <MaterialTable
                            title="Site Address"
                            data={this.state.configData}
                            isLoading={this.state.siteAddressTableLoading}
                            columns={[
                                { title: 'Template Name', field: 'template_name' },
                                { title: 'Site Address', field: 'site_address' },
                            ]}
                            options={{
                                headerStyle: {
                                    fontWeight: 'bold',
                                },
                                filtering: true,
                                padding: 'dense',
                                paging: false
                            }}
                            onRowClick={(event, rowData) => this.setState({ datesDialog: true, selectedConfig: rowData })}
                        />
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default withSnackbar(ReportingInstructions);
