import React from 'react'
import MaterialTable from 'material-table';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import RotateCircleLoading from 'react-loadingg/lib/RotateCircleLoading';
import { Box, Button, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload'
import XLSX from "xlsx";
import { withStyles } from '@material-ui/core/styles';
import { roles_api, roles_api_bulk_update } from '../../config/apiList'
import Modal from '../../shared/Modal/Modal';
import Dropzone from '../../shared/Dropzone/Dropzone';
import { ArchiveOutlined, UnarchiveOutlined } from '@material-ui/icons';
import Auth from '../../utils/authenticate';
import PropTypes from 'prop-types';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    // let _componentStatus.current = useRef(true);
    // useEffect(() => {
    //     let componentMounted = true;
    //       const fetchData = async () => {
    //        //you async action is here
    //         if(componentMounted) {
    //           setData(response?.data);
    //         }
    //       };
    //       fetchData();
    //       return () => {
    //        componentMounted = false;
    //       }
    //     }, []);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const token = Auth.token();

const useStyles = (theme) => ({
});

class Roles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                { title: 'Id', field: 'id', editable: 'never' },
                { title: 'Current Combi Roles', field: 'current_combi_role', validate: rowData => rowData.current_combi_role !== '' },
                { title: 'Job Role', field: 'job_roles', validate: rowData => rowData.job_roles !== '' },
                { title: 'Resource Type', field: 'resource_type', validate: rowData => rowData.resource_type !== '' },
                { title: 'Job Family', field: 'job_family', validate: rowData => rowData.job_family !== '' },
            ],
            data: [],
            isLoading: true,
            reviewModal: false,
            dataToUpload: [],
            dropZoneModal: false,
            tabValue: 0,
            live: [],
            archive: [],
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let resData = res.data;
                let archive = []; let live = [];
                resData.forEach(role => {
                    if (role.is_active === true) {
                        live.push(role)
                    }
                    else archive.push(role)
                })


                this.setState({ live, archive })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false })
                }, 1000);
            });
    }

    postOps(data) {

        Service.post(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                // this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Role successfully added', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        // .finally(() => { this.setState({ isLoading: false }) });;
    }

    putOps(data) {
        if (!data.current_combi_role) {
            this.props.enqueueSnackbar('Combi role field is empty!', {
                variant: 'error'
            });
        }
        else if (!data.job_family) {
            this.props.enqueueSnackbar('Job family field is empty!', {
                variant: 'error'
            });
        }
        else if (!data.resource_type) {
            this.props.enqueueSnackbar('Resource type field is empty!', {
                variant: 'error'
            });
        }
        else if (!data.job_roles) {
            this.props.enqueueSnackbar('Job role field is empty!', {
                variant: 'error'
            });
        }
        else {
            Service.put(roles_api + data.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data,
            })
                .then(res => {
                    this.fetchOps()
                    this.props.enqueueSnackbar('Updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.fetchOps();
                });
        }
    }

    deleteOps(data) {
        Service.delete(roles_api + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchOps();
            });
    }

    rolesBulkPost(data) {
        Service.post(roles_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Row' : ' Rows') + ' successfully added.', {
                    variant: 'success'
                })
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    rolesBulkUpdate(data) {
        Service.patch(roles_api_bulk_update, {
            headers: {
                Authorization: "Token " + token,
            },
            data
        })
            .then(res => {
                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Row' : ' Rows') + ' successfully updated.', {
                    variant: 'success'
                });

            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    reviewModalOpen = () => { this.setState({ reviewModal: true }) }

    reviewModalClose = () => { this.setState({ reviewModal: false }) }

    fileToRead(files) {
        if (!files || !files[0]) return;
        let f = files[0];
        try {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* read workbook */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* save data */
                const data = XLSX.utils.sheet_to_json(ws)

                this.setState({ dataToUpload: data })
            };
            if (rABS) {
                reader.readAsBinaryString(f);
            } else {
                reader.readAsArrayBuffer(f);
            }
        }
        catch (e) {
            console.error(e)
        }
    }



    bulkUpload = () => {

        this.setState({ bulkUploadLoader: true })
        let dataToUpload = [...this.state.dataToUpload]
        let dataToUpdate = []
        dataToUpload.forEach((ele, i) => {
            this.state.data.forEach(ele2 => {
                if (ele.job_roles.toLowerCase() === ele2.job_roles.toLowerCase()) {
                    ele.id = ele2.id;
                    dataToUpdate.push(ele)
                }
            })
        })
        dataToUpdate.forEach((ele) => {
            dataToUpload.forEach((ele2, i) => {
                if (ele.job_roles.toLowerCase() === ele2.job_roles.toLowerCase()) {
                    dataToUpload.splice(i, 1)
                }
            })
        })

        if (dataToUpload.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpload.length; i++) {
                arr.push(dataToUpload[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.rolesBulkPost(arr)
                    arr = []
                }
                if (i === dataToUpload.length) {
                    this.rolesBulkPost(arr)
                }
            }


        }
        if (dataToUpdate.length) {

            let arr = [];
            for (let i = 1; i <= dataToUpdate.length; i++) {
                arr.push(dataToUpdate[i - 1])
                if (i === 1000 * Number((i / 1000).toFixed())) {
                    this.rolesBulkUpdate(arr)
                    arr = []
                }
                if (i === dataToUpdate.length && arr.length) {
                    this.rolesBulkUpdate(arr)
                }
            }
        }

        setTimeout(() => {
            this.setState({
                reviewModal: false,
                dataToUpload: [],
                dropZoneModal: false,
                bulkUploadLoader: false
            })
            this.fetchOps()
        }, 4000);

    }

    dropzoneClose = () => {
        this.setState({ dropZoneModal: false })
    }



    handleChange(event, newValue) {

        if (newValue === 0) {
            this.setState({ tabValue: newValue })
        }
        if (newValue === 1) {
            this.setState({ tabValue: newValue })
        }
    };

    render() {
        const access = this.props.access.access
        return (
            <>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :


                    <Paper square>
                        <Tabs
                            value={this.state.tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, newValue) => this.handleChange(e, newValue)}
                            aria-label="Role Tabs"
                            centered
                        >
                            <Tab label="Live" />
                            <Tab label="Archive" />
                        </Tabs>
                        <TabPanel value={this.state.tabValue} index={0}>
                            <MaterialTable
                                title={<div><h2 style={{ float: 'left' }}>Live Roles</h2>&emsp;&emsp;
                                    {access.l3a && access.l3a.l3a_d ? <Button
                                        variant="outlined"
                                        size="small"
                                        style={{ marginTop: '15px', marginLeft: '80px' }}
                                        onClick={() => this.setState({ dropZoneModal: true })}
                                    >
                                        Upload Roles Data Excel&ensp;<UploadIcon />
                                    </Button> : null}
                                </div>}
                                options={{
                                    paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - 250,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    filtering: true,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    padding: 'dense'
                                }}
                                columns={this.state.columns}
                                data={this.state.live}
                                isLoading={this.state.isLoading}
                                editable={{
                                    onRowAdd: access.l3a && access.l3a.l3a_b ? newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.postOps(newData)
                                                resolve();
                                            }, 1000)
                                        }) : undefined,
                                    onRowUpdate: access.l3a && access.l3a.l3a_c ? (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.putOps(newData)
                                                resolve();
                                            }, 1000)
                                        }) : undefined
                                }}
                                actions={access.l3a && access.l3a.l3a_c ? [
                                    // {
                                    //     icon: (data) => <ArchiveOutlined />,
                                    //     tooltip: 'Move to archive',
                                    //     onClick: (event, rowData) => {
                                    //         let data = { ...rowData }
                                    //         data.is_active = false
                                    //         this.putOps(data)
                                    //     }
                                    // },

                                    rowData => ({
                                        icon: () => rowData.is_active === true ? <ArchiveOutlined /> : <UnarchiveOutlined />,
                                        tooltip: rowData.is_active === true ? 'Archive' : 'Unarchive',
                                        onClick: (event, rowData) => {
                                            let data = { ...rowData }

                                            data.is_active = this.state.tabValue === 0 ? false : true;

                                            this.putOps(data)
                                        }
                                    }),

                                ] : []}

                            />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1}>
                            <MaterialTable
                                title={<div><h2 style={{ float: 'left' }}>Archive Roles</h2>&emsp;&emsp;
                                    {access.l3a && access.l3a.l3a_d ? <Button
                                        variant="outlined"
                                        size="small"
                                        style={{ marginTop: '15px', marginLeft: '80px' }}
                                        onClick={() => this.setState({ dropZoneModal: true })}
                                    >
                                        Upload Roles Data Excel&ensp;<UploadIcon />
                                    </Button> : null}
                                </div>}
                                options={{
                                    paging: false,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    // doubleHorizontalScroll: true,
                                    // maxBodyHeight: window.screen.height - 250,
                                    headerStyle: {
                                        fontWeight: 'bold',
                                        // color: '#005D99',
                                    },
                                    filtering: true,
                                    actionsColumnIndex: -1,
                                    addRowPosition: 'first',
                                    padding: 'dense'
                                }}
                                columns={this.state.columns}
                                data={this.state.archive}
                                isLoading={this.state.isLoading}
                                editable={{
                                    onRowAdd: access.l3a && access.l3a.l3a_b ? newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.postOps(newData)
                                                resolve();
                                            }, 1000)
                                        }) : undefined,
                                    onRowUpdate: access.l3a && access.l3a.l3a_c ? (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.putOps(newData)
                                                resolve();
                                            }, 1000)
                                        }) : undefined
                                }}
                                actions={access.l3a && access.l3a.l3a_c ? [
                                    // {
                                    //     icon: (data) => <ArchiveOutlined />,
                                    //     tooltip: 'Move to archive',
                                    //     onClick: (event, rowData) => {
                                    //         let data = { ...rowData }
                                    //         data.is_active = false
                                    //         this.putOps(data)
                                    //     }
                                    // },

                                    rowData => ({
                                        icon: () => rowData.is_active === true ? <ArchiveOutlined /> : <UnarchiveOutlined />,
                                        tooltip: rowData.is_active === true ? 'Archive' : 'Unarchive',
                                        onClick: (event, rowData) => {
                                            let data = { ...rowData }

                                            data.is_active = this.state.tabValue === 0 ? false : true;

                                            this.putOps(data)
                                        }
                                    }),

                                ] : []}

                            />
                        </TabPanel>
                        <Dropzone
                            open={this.state.dropZoneModal}
                            onClose={this.dropzoneClose}
                            onChange={(e) => this.fileToRead(e)}
                            preview={this.reviewModalOpen}
                        />


                        <Modal
                            open={this.state.reviewModal}
                            close={this.reviewModalClose}
                            onUpload={this.bulkUpload}
                            columns={this.state.columns}
                            data={this.state.dataToUpload}
                            loading={this.state.bulkUploadLoader}
                            title={"Roles excel data review"}
                        />
                    </Paper>



                }
            </>
        )
    }
}

export default withSnackbar(withStyles(useStyles)(Roles))