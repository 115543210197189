import React, { createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack";
import { LoopCircleLoading } from 'react-loadingg';
import { getActiveRoles, getAllElectricityOHLTcRMs, getEmployees, getGeneralforeman, getGeneralforemanGang, getOwnerDelivery, getRegion, getSubSector } from '../../../../config/PromisesHandler';
import ResourceLocation from './ResourceLocation';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Button, Dialog, DialogActions, DialogContentText, Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import XLSX from "xlsx";
import { roles_crm_emp } from '../../../../config/apiList';
import { getCookie } from '../../../../utils/CookieHelper';
import Service from '../../../../config/networkutils';
import { groupByField } from '../../../../utils/reuseFunctions';
import { weekStartDates } from '../../../../utils/betweenDates';
import moment from 'moment';

const token = getCookie("ptd_ts_token");

const useStyle = (theme) => ({
    headRow: {
        backgroundColor: "#D5E1E5",
        // minWidth: '100%'
    },
    selectedRow: {
        backgroundColor: "#EDFAFF",
    },
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const user = JSON.parse(getCookie("ptd_ts_user"));

class GfsTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            gfs: [],
            showGFAdd: false,
            isLoading: true,
            allOHLEmp: [],
            selectedRoles: [],
            selectedCrms: [],
            selectedEmployees: [],
            getReport: false,
            allGangmen: [],
            isResourceLoading: true,
            allElectricityOHLTcRMs: [],
            allOHLEmps: [],
            selectedStartDate: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
            selectedEndDate: moment().add(6, 'months').endOf('month').format('YYYY-MM-DD'),
            excelExportDialog: false,
        }
        this.filter = createRef()
        this.getDates = this.getDates.bind(this)
        this.callMakeReport = this.callMakeReport.bind(this)
    }
    // ohlroles = [
    //     { 58: "General Foreman" },
    //     { 171: "Apprentice General Foreman" },
    //     { 124: "Senior General Foreman" },
    //     { 45: "Drivers" },
    //     { 138: "Storeman" },
    //     { 196: "General Operative" },
    //     { 203: "Foreman 1" },
    //     { 204: "Foreman 2" },
    //     { 205: "LE1" },
    //     { 206: "LE2" },
    //     { 207: "LH" },
    //     { 208: "Apprentice Linesman" },
    //     { 209: "Trainee Linesman" },
    //     { 210: "SPO" },
    // ]

    getEmpFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: `${ele.first_name} ${ele.last_name}` } }
        })
        return obj
    }

    getFomattedLookupObj(arr) {
        let obj = {}
        arr.forEach(ele => {
            obj = { ...obj, ...{ [ele.id]: ele.name } }
        })
        return obj
    }

    getPRPR(empIDSArray) {
        return new Promise((resolve, reject) => {

            Service.get(roles_crm_emp + `?is_archive=0&emp=${empIDSArray.join()}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => {
                resolve(res.data)
            }
            ).catch(error => {
                reject(error)
            }
            )
        })
    }

    gfRoleId = null
    ohlroles = null

    fecthEmp() {
        this.setState({ allElectricityOHLTcRMs: [], allOHLEmps: [] })
        let url = `/api/gang/report/roles/get_gangmens_project_and_emp_list/`
        url += `?category=${this.props.gangStructure.id}&genera_foreman_id=${this.props.rowData.id}&from_date=${this.state.selectedStartDate}&to_date=${this.state.selectedEndDate}`
        Service.get(url, {
            headers: { Authorization: "Token " + token, }
        })
            .then(res => {
                this.setState({
                    allElectricityOHLTcRMs: res.data.project_details,
                    allOHLEmps: res.data.emp_details,
                })
            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                this.setState({ isLoading: false })
            })
    }

    componentDidMount() {

        this.ohlroles = this.props.roles
        this.gfRoleId = this.props.gfRoleId

        this.fecthEmp()

        let searchParams = new URLSearchParams(window.location.search)
        searchParams.delete("employee")

        Promise.all([getEmployees(), getGeneralforeman(this.props.discipline), getActiveRoles(), getSubSector(), getRegion(), getOwnerDelivery(), getAllElectricityOHLTcRMs(), getGeneralforemanGang()])
            .then(res => {
                let ohlemps = []
                let allOHLEmps = []
                // Filtering all the OHL Role employees mentioned in the this.ohlroles
                for (let empin = 0; empin < res[0].length; empin++) {
                    this.ohlroles.forEach(roleId => {
                        if (res[0][empin].job_title === roleId) {
                            ohlemps.push(res[0][empin])
                            allOHLEmps.push(res[0][empin])
                        }
                    })
                }

                // filtering only generalforemen role
                let allGfs = ohlemps.filter(emp => emp.job_title === this.gfRoleId)
                let allGangEmps = ohlemps.filter(emp => emp.job_title !== this.gfRoleId)


                // filtering not Allocated generalforemen
                let unAllocatedGF = [...allGfs];
                for (let gfEmpIndex = 0; gfEmpIndex < res[1].length; gfEmpIndex++) {
                    let filteredGfNotAllocatedEmps = unAllocatedGF.filter(emp => emp.id !== res[1][gfEmpIndex].ptd_employee)
                    unAllocatedGF = [...filteredGfNotAllocatedEmps]
                }

                let rolesObj = {}
                res[2].forEach(ele => {
                    let newData = { [ele.id]: ele.job_roles }
                    rolesObj = { ...rolesObj, ...newData }
                })
                // getting lookup objects
                let ohlEempObj = this.getEmpFomattedLookupObj(ohlemps)
                let sec = this.getFomattedLookupObj(res[3])
                let reg = this.getFomattedLookupObj(res[4])
                let odu = this.getFomattedLookupObj(res[5])
                let live = []

                for (let index = 0; index < res[6].length; index++) {
                    const element = res[6][index];
                    if (element.archive_reason === 0) {
                        live.push(element)
                    }

                }
                this.setState({ allGfs, ohlEempObj, unAllocatedGF, allGangEmps, gfs: res[1], rolesObj, sector: sec, owner_region: reg, owner_delivery_unit: odu, allGangmen: res[7], isResourceLoading: false }, () => this.setState({ isLoading: false }))
            })

            .catch(e => {
                this.setState({ isLoading: false })
            })
    }

    handleRoleChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRolesIds = [];
            value.forEach((region) => {
                selectedRolesIds.push(region.id)
            })
            if (searchParams.has("role")) {
                searchParams.set("role", selectedRolesIds.join(","))
            } else {
                searchParams.append("role", selectedRolesIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("role")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedRoles: value, reportedRoles: value, getReport: false })
    }

    handleCrmChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedCrmIds = [];
            value.forEach((crm) => {
                selectedCrmIds.push(crm.crm_id)
            })
            if (searchParams.has("crm")) {
                searchParams.set("crm", selectedCrmIds.join(","))
            } else {
                searchParams.append("crm", selectedCrmIds.join(","))
            }

        } else {
            searchParams.delete("crm")
        }

        // this.setState({ isResourceLoading: true }, () => {
        //     this.setState({ isResourceLoading: false })
        // })
        if(this.filter && this.filter.current){
            this.filter.current.reload()
        }
        this.setState({ getReport: true })

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedCrms: value, getReport: false })
    }

    handleEmployeeChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedCrmIds = [];
            value.forEach((crm) => {
                selectedCrmIds.push(crm.employee_id)
            })
            if (searchParams.has("employee")) {
                searchParams.set("employee", selectedCrmIds.join(","))
            } else {
                searchParams.append("employee", selectedCrmIds.join(","))
            }
        } else {
            searchParams.delete("employee")
        }

        // this.setState({ isResourceLoading: true }, () => {
        //     this.setState({ isResourceLoading: false })
        // })
        if(this.filter && this.filter.current){
            this.filter.current.reload()
        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedEmployees: value, getReport: false })

    }

    handleGangStructureDownload(allGfs, ohlEempObj, allGangEmps, allGangmen, gfs) {

        let allGangEmpsIds = []
        allGangEmps.forEach(gangman => {
            allGangEmpsIds.push(gangman.id)
        })

        let allGfIds = []
        allGfs.forEach(gf => {
            allGfIds.push(gf.id)
        })

        const gfGroups = groupByField(allGangmen, 'general_foreman')
        let gangManCrmGroup = []

        // let gfCrmGroup = []  //eslint-disabled-next-line


        let gfEmpIdConvertedObj = {}

        gfs.forEach(gf => {

            for (let groupIndex = 0; groupIndex < Object.keys(gfGroups).length; groupIndex++) {
                const gfGroup = Object.keys(gfGroups)[groupIndex];

                if (gf.id === gfGroups[gfGroup][0].general_foreman) {

                    gfEmpIdConvertedObj = { ...gfEmpIdConvertedObj, [gf.ptd_employee]: [...gfGroups[gfGroup]] }
                }
            }

        })
        // for (let groupIndex = 0; groupIndex < Object.keys(gfGroups).length; groupIndex++) {
        //     const gfGroup = Object.keys(gfGroups)[groupIndex];

        //         if (gf.id === gfGroups[gfGroup][0].general_foreman) {

        //             gfEmpIdConvertedObj = { ...gfEmpIdConvertedObj, [gf.ptd_employee]: [...gfGroups[gfGroup]] }
        //         }
        //     }

        let allWeekDates = weekStartDates(moment().subtract(6, 'months').startOf('month'), moment().add(18, 'months').endOf('month'));

        let headerArray = ["EmployeeNo", 'EmployeeName', "Role", ...allWeekDates]

        const gangStructureWorkbook = XLSX.utils.book_new();


        Promise.all([this.getPRPR(allGangEmpsIds), this.getPRPR(allGangEmpsIds)]).then((res) => {

            gangManCrmGroup = groupByField(res[0], 'emp_id')
            // gfCrmGroup = groupByField(res[1], 'emp_id')

            for (let gfIndex = 0; gfIndex < gfs.length; gfIndex++) {
                const gf = gfs[gfIndex];
                let gfSheetArray = [headerArray]
                for (let gangManIndex = 0; gangManIndex < gfEmpIdConvertedObj[gf.ptd_employee].length; gangManIndex++) {
                    const gangman = gfEmpIdConvertedObj[gf.ptd_employee][gangManIndex];

                    let gangManRow = [gangman.ptd_employee_data.emp_no, `${gangman.ptd_employee_data.first_name} ${gangman.ptd_employee_data.last_name}`, this.state.rolesObj[gangman.ptd_employee_data.job_title]]

                    if (gangManCrmGroup.hasOwnProperty(gangman.ptd_employee)) {
                        for (let gangManCrmIndex = 0; gangManCrmIndex < gangManCrmGroup[gangman.ptd_employee].length; gangManCrmIndex++) {
                            const gangManCrm = gangManCrmGroup[gangman.ptd_employee][gangManCrmIndex];

                            for (let m = 0; m < allWeekDates.length; m++) {
                                let date = allWeekDates[m];

                                if (gangManCrm.dates) {
                                    if (gangManCrm.dates.hasOwnProperty(date) && gangManCrm.dates[date] !== 0) {


                                        if (gangManRow.indexOf(date) === -1) {

                                            gangManRow = [...gangManRow, gangManCrm.crm_opportunity_id]

                                        } else {
                                            // If already a project in the week then concatenate the date
                                            gangManRow[date] = [...gangManRow, " "]
                                        }
                                    }
                                }


                            }
                        }
                    }

                    gfSheetArray.push(gangManRow)
                }
                var worksheet = XLSX.utils.aoa_to_sheet(gfSheetArray)
                XLSX.utils.book_append_sheet(gangStructureWorkbook, worksheet, ohlEempObj[gf.ptd_employee]);
            }
            XLSX.writeFile(gangStructureWorkbook, "gangStructure.xlsx");

        }).catch((error) => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    handleClick(value) {
        this.props.data(value)
    }

    getDates(startDate, endDate) {
        this.setState({ selectedStartDate: startDate, selectedEndDate: endDate }, () => {
            // this.fecthEmp()
        })
    }

    emailReport() {
        let url = `/api/prp/rolenew/get_staff_location_data/?archive_reason=0&get_report=true&general_foreman=${this.props.rowData.id}&from_date=${this.state.selectedStartDate}&to_date=${this.state.selectedEndDate}`
        if (this.state.selectedCrms.length > 0) {
            for (let index = 0; index < this.state.selectedCrms.length; index++) {
                const element = this.state.selectedCrms[index];
                url += `&crm=${element.crm_id}`
            }
        }
        if (this.state.selectedEmployees.length > 0) {
            for (let index = 0; index < this.state.selectedEmployees.length; index++) {
                const element = this.state.selectedEmployees[index];
                url += `&emp=${element.employee_id}`
            }
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ excelExportDialog: true })
        }
        ).catch(error => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        }
        )
    }

    callMakeReport(startDate, endDate) {
        this.setState({
            selectedStartDate: startDate,
            selectedEndDate: endDate
        })
    }

    render() {
        return (
            <div>
                {
                    // this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                    <div>
                        <Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: '15px' }}>
                            <Grid item xs={3}>
                                <Typography variant="h6" >
                                    {this.props.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    multiple
                                    id="Crm"
                                    options={this.state.allElectricityOHLTcRMs}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.project_name}
                                    onChange={(e, value) => this.handleCrmChange(e, value)}
                                    value={this.state.selectedCrms}
                                    size="small"
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.project_name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Crm" placeholder="Crm" />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Autocomplete
                                    multiple
                                    id="Employee"
                                    options={this.state.allOHLEmps}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                    onChange={(e, value) => this.handleEmployeeChange(e, value)}
                                    value={this.state.selectedEmployees}

                                    size="small"
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.first_name + ' ' + option.last_name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (

                                        <TextField {...params} variant="outlined" label="Employee" placeholder="Employee" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ display: "flex", alignItems: 'center' }}>
                                <Button size='small' variant="outlined" color="primary" onClick={() => { this.emailReport() }}>Excel Export</Button>
                            </Grid>
                        </Grid>
                        {this.state.isResourceLoading ? <LoopCircleLoading color='#005D99' /> :
                            <ResourceLocation
                                getDates={this.getDates}
                                selectedGang={this.props.selectedGang}
                                ref={this.filter}
                                selectedCrms={this.state.selectedCrms}
                                selectedEmployees={this.state.selectedEmployees}
                                ohlEmpObj={this.state.ohlEempObj}
                                allOHLEmp={this.state.allGangEmps}
                                sector={this.state.sector}
                                owner_delivery_unit={this.state.owner_delivery_unit}
                                owner_region={this.state.owner_region}
                                gf={this.props.rowData}
                                rolesObj={this.state.rolesObj}
                                gangEmployees={this.state.allGangmen.filter(gm => gm.general_foreman === this.props.rowData.id)}
                                id={this.props.rowData.ptd_employee}
                                callMakeReport={this.callMakeReport}
                            />}
                    </div>
                }
                <Dialog open={this.state.excelExportDialog} onClose={() => this.setState({ excelExportDialog: false })} >
                    <DialogContentText>
                        <Box margin={2}>
                            The Report will be emailed to {user.email}
                        </Box>
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => this.setState({ excelExportDialog: false })} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(withStyles(useStyle)(GfsTable));